import styled, {css} from 'styled-components';
import { fontGrotesk, screenSize } from './mixins';

export const StyledButton = styled.button`
    position: relative;
    box-sizing: content-box;
    text-transform: uppercase;
    padding: .5rem 1rem;
    color: #222;
    font-weight: 700;
    font-family: inherit;
    background-image: url(${(props) => props.bg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 1.3rem;
    line-height: 1.5;
    width: 25rem;
    height: 5rem;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & span {
        display: block;
        width: 100%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    :hover {
        span {
            color: white;
        }
    }

    :disabled {
        cursor: default;
        opacity: 0.8;
        :hover {
            span {
                color: #222;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.3rem;
    }

    &.clicked {
        background-image: url(${(props) => props.bg}) !important;
    }
`;

export const StyledOpenButton = styled.button`
    position: relative;
    box-sizing: content-box;
    text-transform: uppercase;
    background-image: url(${(props) => props.bg}) !important;
    color: #add8e6 !important;
    padding: .5rem 1rem;
    font-weight: 700;
    font-family: inherit;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 1.3rem;
    width: 25rem;
    height: 5rem;
    text-align: center;
    border-radius: 15px 0px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 1200px) {
        font-size: 1.4rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.3rem;
    }

    & span {
        display: block;
        
        &:nth-of-type(2) {
            margin-left: 2rem;
        }
    }
   
    &.clicked {
        background-image: url(${(props) => props.bg})!important;
    }

`;

export const CampaignButtonStyle = styled.button`
    border-radius: 46px;
    padding: 0.7rem 3rem;
    cursor: pointer;
    outline: none;
    ${fontGrotesk({size: "1.25rem", lineHeight: "1.43rem", weight: 700})};
    text-transform: capitalize;

    ${props => props.color && css`
        color: ${props.color};
    `};

    ${props => props.border && css`
        border: 2px solid ${props.border};
    `}; 

    ${props => props.bg ? css`
        background-color: ${props.bg};
    `
    : css`
        background-color: white;
    `};

    ${screenSize.tab`
        padding: 0.5rem 2rem;
        font-size: 1.1rem;
    `}
    ${screenSize.mobile`
        padding: 0.3rem 1.5rem;
        font-size: 1rem;
    `}

    &:hover {
        color: ${props => props.hoverColor};
        background-color: ${props => props.hoverBg};
    }
`