import React from "react";
// import { Link } from "react-router-dom";
import classNames from "classnames";
import Logo from "./Logo";
import { ContactStyle } from "../styles/contact.style";

const Contact = ({ onFootSelect, activeNav }) => {
  const contactBg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317382/madwallet/contactBg_ikdld0.png";

  // const [contact, setContact] = useState(false);

  return (
    <ContactStyle bg={contactBg}>
      <div className="footer__content--box">
        <div className="footer__logo">
          <Logo section="footer" />
        </div>
        <nav className="footer__nav-list">
          <div
            className={classNames("footer__nav-item", {
              activeLink: activeNav === "landing",
            })}
            onClick={(e) => onFootSelect(e)}
          >
            <button className="footer__nav-link" name="landing">
              Home
            </button>
          </div>
          <div
            className={classNames("footer__nav-item", {
              activeLink: activeNav === "features",
            })}
            onClick={(e) => onFootSelect(e)}
          >
            <button className="footer__nav-link" name="features">
              Features
            </button>
          </div>
          <div
            className={classNames("footer__nav-item", {
              activeLink: activeNav === "madverse",
            })}
            onClick={(e) => onFootSelect(e)}
          >
            <button className="footer__nav-link" name="madverse">
              Madverse
            </button>
          </div>
          <div
            className={classNames("footer__nav-item", {
              activeLink: activeNav === "newsletter",
            })}
            onClick={(e) => onFootSelect(e)}
          >
            <button className="footer__nav-link" name="newsletter">
              News
            </button>
          </div>
          <div
            className={classNames("footer__nav-item", {
              activeLink: activeNav === "community",
            })}
            onClick={(e) => onFootSelect(e)}
          >
            <button className="footer__nav-link" name="community">
              Community
            </button>
          </div>
          <div className="footer__nav-item lang">
            <span className="nav-links">En</span>
          </div>
        </nav>

        <div
          className="footer__contact"
        >
          <p>
            <a href="mailto:hello@xend.finance">
              <svg
                viewBox="0 0 512 512"
                width="30"
                fill="#ffffff"
                title="envelope"
              >
                <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
              </svg>
              Email Us
            </a>
          </p>
        </div>

        <div className="footer--copyright">
          <p className="copyright">
            &copy; Copyright &copy; {new Date().getFullYear()}, All rights reserved.
          </p>
        </div>
      </div>
    </ContactStyle>
  );
};

export default Contact;
