import styled from "styled-components"
import { flex, fontGrotesk, screenSize } from "./mixins"

export const FaqStyle = styled.div`
    width: 60%;
    height: auto;
    /* border: 2px solid red; */
    ${screenSize.tab`
        width: 80%;
    `}

.faq-header {
    ${fontGrotesk({size: "1.5rem", lineHeight: "2rem", weight: "700", color: "#B490FF"})};
    text-align: center;
    margin: 1rem 0;
}

.enquiry{
    margin-bottom: 1rem;
    
    .question-box{
        background-color: #433565;
        width: 100%;
        height: 100%;
        ${flex({direction: "row", justify: "space-between", align: "center"})};
        box-sizing: border-box;
        padding: 1.5rem 1.5rem;
        cursor: pointer;
        border-radius: 10px;

        #question{
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 1.25rem;
            color: #CEB7FF;
            transition: all .5s cubic-bezier(.93,-.01,.36,.99);
        }

        #arrow{
            // cursor: pointer;
        }
    }

    #show-answer{
        // display: block;
        width: 100%;
        height: auto;
        padding: 1.5rem;
        background-color: #CEB7FF;;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: #433565;
        transition: height 2s;
        // margin: 0.5rem 0;
        // transition: all 2s cubic-bezier(0.93, -0.01, 0.36, 0.99);
    }

    #hide-answer{
        // display: none;
        height: 0;
        width: 0;
        transition: height 2s;
        // transition: all 2s cubic-bezier(0.93, -0.01, 0.36, 0.99);
    }

}

`