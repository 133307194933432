import styled from 'styled-components';

export const StarsStyles = styled.div`
width: 100vw;
height: 100vh;
/* background-color: red; */
position: relative;

.star {
    position: absolute;
    background-color: #fff;
    display: block;
    border-radius: 50%;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    10%, 90% {
        opacity: 1;
    }
    
    100% {
        opacity: 0;
        transform: translateY(-100px);
    }
}
`;