import React from 'react';
/**Pages */
import Home from '../components/Home';
import Features from '../components/Features';
import Madverse from '../components/Madverse';
import DownloadMadwallet from '../components/DownloadMadwallet';
import Community from '../components/Community';
import Contact from '../components/Contact';

const Landing = ({activeTab, activeNav, handleFooterNavSelect, screenSize }) => {
  return (
    <div className='container'>
      <Home/>
      <Features
        activeTab={activeTab}
      />
      <Madverse/>
      <DownloadMadwallet/>
      <Community/>
      <Contact
        activeNav={activeNav}
        onFootSelect={handleFooterNavSelect}
      />
    </div>
  )
}

export default Landing;