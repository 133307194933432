import styled from 'styled-components';

export const LogoStyle = styled.figure`
    .header__logo--link {
        width: 10rem;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            /* width: 6rem; */
        }

        img {
            width: 100%;
        }
    }

    .footer__logo--link {
        width: 25rem;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            width: 15rem;
        }

        img {
            width: 100%;
        }
    }
`