import React, { useState , useEffect } from 'react';
import { StarsStyles } from '../styles/stars.style';

function Stars() {
    const [numOfStars, setNumOfStars] = useState([]);
    useEffect(() => {
        let counter = 500;
        let i = 0;
        let arr = []

        while(i < counter) {
            arr.push(i)
           i++;
        }
        setNumOfStars(arr);
    },[]);
  return (
    <StarsStyles>
      {
          numOfStars.map((num, idx) => {
            let xPosition = Math.floor(Math.random() * window.innerWidth);
            let yPosition = Math.floor(Math.random() * window.innerHeight);
            let size = Math.random() * 2;
            let duration = Math.random() * 10;

              return (
                  <span
                    className='star' 
                    key={idx}
                    style={{
                        width: size,
                        height: size,
                        top: yPosition,
                        left: xPosition,
                        animation: `animate ${5 + duration}s linear ${duration}s infinite`
                    }}
                  ></span>
              )
        })
      }
    </StarsStyles>
  )
}

export default Stars
