import React from "react";
import { TabStyle } from "../styles/tab.style";

export const Tab = ({active, text, id, onClick}) => {
    return (
      <TabStyle>
      <div className={`tab ${active === id && "tab_active"}`} onClick={onClick}>
        <p className='tab_text'>{text}</p>
      </div>
      </TabStyle>
    )
}