import React, { Fragment } from "react";

const Accounts = ({ screenSize, onToggleMenu }) => {
  // const importIcon = "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317361/madwallet/importIcon_f8eldm.png";
  const toggleIcon = "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317361/madwallet/toggleIcon_ke4v8m.png";
  return (
    <Fragment>
      <nav className="accounts">
        {/* <div className="acct-item">
          <button className="mdw-nav__link-account">
            <img src={importIcon} alt="import from cloud" />
          </button>
        </div> */}
        <div className="acct-item">
          {
            (screenSize === 'desktop') ? (<span>EN</span>) : null
          }
        </div>
      </nav>

      <div className="toggle-box" onClick={ onToggleMenu }>
        <img src={toggleIcon} alt="toggle mobile menu"/>
      </div>
    </Fragment>
  );
};

export default Accounts;
