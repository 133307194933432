import React, { useState, Fragment, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Header from "./components/Header";
import Landing from "./pages/Landing";
import { GlobalStyles } from "./styles/GlobalStyles.style";
import Loader from "./components/Loader";
/**fonts */
import headingFont1 from "./assets/fonts/bd_cartoon_shout-webfont.woff2";
import headingFont2 from "./assets/fonts/bd_cartoon_shout-webfont.woff";
import headingFont3 from "./assets/fonts/bd_cartoon_shout-webfont.ttf";
import navFont1 from "./assets/fonts/TrebuchetMS.ttf";
import navFont2 from "./assets/fonts/TrebuchetMS.woff";
import navFont3 from "./assets/fonts/TrebuchetMS.woff2";
import DownloadExtenstion from "./components/DownloadExtenstion";
import { Leaderboard } from "./pages/Leaderboard";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {

  const [activeTab, setActiveTab] = useState("savings");
  const [activeNav, setActiveNav] = useState("landing");
  const [loader, setLoader] = useState(true);
  const [timer, setTimer] = useState(5);

  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
    setLoader(false);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  useEffect(() => {
    if (loader) return;
    gsap.registerPlugin(ScrollToPlugin);

    const landing = document.querySelector("#landing");
    const madverse = document.querySelector("#madverse");
    const downloadMadwallet = document.querySelector("#download-madwallet");
    const community = document.querySelector("#community");
    let sections = document.querySelectorAll(".panel");

    window.addEventListener("scroll", (event) => {
      if (window.location.pathname !== '/') {
        return
      }

      let scrollY = window.scrollY;
      if (scrollY >= 0 && scrollY < landing.clientHeight * 2) {
        setActiveNav("landing");
      } else if (
        scrollY >= landing.clientHeight * 2 &&
        scrollY < madverse.offsetTop
      ) {
        setActiveNav("features");
      } else if (scrollY >= madverse.offsetTop && scrollY < downloadMadwallet.offsetTop) {
        setActiveNav("madverse");
      } else if (scrollY >= downloadMadwallet.offsetTop && scrollY < community.offsetTop) {
        setActiveNav("download-madwallet");
      } else {
        setActiveNav("community");
      }
      // Horizontal section
      if (
        scrollY < window.innerHeight * 2 + (sections[0].offsetWidth / 5) - 10
      ) {
        setActiveTab("savings");
      } else if (
        scrollY >= window.innerHeight * 2 + (sections[0].offsetWidth / 5) - 10 &&
        scrollY < window.innerHeight * 2 + (sections[1].offsetWidth * 2 / 5) - 10
      ) {
        setActiveTab("cross-chain");
      } else if (
        scrollY >= window.innerHeight * 2 + (sections[1].offsetWidth * 2) / 5 - 10 &&
        scrollY < window.innerHeight * 2 + (sections[2].offsetWidth * 3) / 5 - 10
      ) {
        setActiveTab("market");
      } else if (
        scrollY >= window.innerHeight * 2 + (sections[2].offsetWidth * 3) / 5 - 10 &&
        scrollY < window.innerHeight * 2 + (sections[3].offsetWidth * 4) / 5 - 10
      ) {
        setActiveTab("customization");
      } else {
        setActiveTab("rwa-aggregator");
      }
    });
  }, [loader]);

  
  const handleNavSelect = (e) => {
    e.preventDefault();

    let scrollPosition;

    if (e.target.dataset.section) {
      setActiveNav(e.target.dataset.section);
      const section = document.querySelector(`#${e.target.dataset.section}`);
      if (e.target.dataset.section === "features") {
        scrollPosition = section.clientHeight * 2;
      } else {
        scrollPosition = section.offsetTop;
      }

      gsap.to(window, { duration: 1, scrollTo: { y: scrollPosition } });
    }
    return;
  };

  const handleFooterNavSelect = (e) => {
    e.preventDefault();
    console.log(e.target.getAttribute("name"));
    let currentName, scrollPosition;
    if (e.target.getAttribute("name")) {
      currentName = e.target.getAttribute("name");
    } else if (e.target.parentNode.getAttribute("name")) {
      currentName = e.target.parentNode.getAttribute("name");
    }

    setActiveNav(currentName);

    setTimeout(() => {
      const section = document.querySelector(
        `#${e.target.getAttribute("name")}`
      );
      if (e.target.getAttribute("name") === "features") {
        scrollPosition = section.clientHeight * 2;
      } else {
        scrollPosition = section.offsetTop;
      }

      gsap.to(window, { scrollTo: scrollPosition });

      return;
    }, 500);
  };

  return (
    <Fragment>
        <Fragment>
          <Loader isLoading={loader}/>
          <QueryClientProvider client={queryClient}>
          <GlobalStyles
            ft1={headingFont1}
            ft2={headingFont2}
            ft3={headingFont3}
            ft4={navFont1}
            ft5={navFont2}
            ft6={navFont3}
          />
          <Router>
            <Header activeNav={activeNav} handleNavSelect={handleNavSelect} />
            <Routes>
              <Route exact path="/download" element={<DownloadExtenstion/>}/>
              <Route
                exact
                path="/"
                element={
                  <Landing
                    activeNav={activeNav}
                    activeTab={activeTab}
                    handleFooterNavSelect={handleFooterNavSelect}
                  />
                }
              />
              <Route
                exact
                path="/leaderboard"
                element={<Leaderboard />}
              />
            </Routes>
          </Router>
          </QueryClientProvider>
        </Fragment>
    </Fragment>
  );
}

export default App;
