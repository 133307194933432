export const screenSizes = [
  {
    // larger Monitors
    size: '(min-width: 1700px)',
    width: 1700,
    options: {
      worldOne: {
        xPosition: -350,
        yPosition: -120
      },
      meteroOne: {
        xPosition: -60,
        yPosition: -350
      },
      meteroTwo: {
        xPosition: 80,
        yPosition: -250
      },
      meteroThree: {
        xPosition: 40,
        yPosition: -340
      },
      worldTwo: {
        left: 650
      },
      spacemanWrap: {
        top: '15%',
        left: '15%'
      },
    }
  },
  {
    // large Monitors
    size: '(min-width: 1400px) and (max-width: 1699px)',
    width: 1690,
    options: {
      worldOne: {
        xPosition: -350,
        yPosition: -120
      },
      meteroOne: {
        xPosition: -110,
        yPosition: -350
      },
      meteroTwo: {
        xPosition: 0,
        yPosition: -250
      },
      meteroThree: {
        xPosition: 30,
        yPosition: -340
      },
      worldTwo: {
        left: 650
      },
      spacemanWrap: {
        top: '15%',
        left: '15%'
      },
    }
  },
  {
    // laptops
    size: '(min-width: 1301px) and (max-width: 1399px)',
    width: 1399,
    options: {
      worldOne: {
        xPosition: -200,
        yPosition: -120
      },
      meteroOne: {
        xPosition: 10,
        yPosition: -250
      },
      meteroTwo: {
        xPosition: 120,
        yPosition: -200
      },
      meteroThree: {
        xPosition: 180,
        yPosition: -290
      },
      worldTwo: {
        left: 600
      },
      spacemanWrap: {
        top: '10%',
        left: '15%'
      },
    }
  },
  {
    // medium laptops
    size: '(min-width: 1201px) and (max-width: 1300px)',
    width: 1300,
    options: {
      worldOne: {
        xPosition: -200,
        yPosition: -120
      },
      meteroOne: {
        xPosition: 0,
        yPosition: -330
      },
      meteroTwo: {
        xPosition: 80,
        yPosition: -270
      },
      meteroThree: {
        xPosition: 170,
        yPosition: -350
      },
      worldTwo: {
        left: 600
      },
      spacemanWrap: {
        top: '10%',
        left: '15%'
      },
    }
},
  {
    // small laptops
    size: '(min-width: 993px) and (max-width: 1200px)',
    width: 1200,
    options: {
      worldOne: {
        xPosition: -150,
        yPosition: -80
      },
      meteroOne: {
        xPosition: 20,
        yPosition: -280
      },
      meteroTwo: {
        xPosition: 100,
        yPosition: -210
      },
      meteroThree: {
        xPosition: 160,
        yPosition: -300
      },
      worldTwo: {
        left: 600
      },
      spacemanWrap: {
        top: '30%',
        left: '30%'
      },
    }
  },
  {
    // tablets
    size: '(min-width: 769px) and (max-width: 992px)',
    width: 992,
    options: {
      worldOne: {
        xPosition: -50,
        yPosition: -80
      },
      meteroOne: {
        xPosition: 80,
        yPosition: -260
      },
      meteroTwo: {
        xPosition: 150,
        yPosition: -200
      },
      meteroThree: {
        xPosition: 150,
        yPosition: -320
      },
      worldTwo: {
        left: 600
      },
      spacemanWrap: {
        top: '30%',
        left: '30%'
      },
    }
  },
  {
    // small tablets
    size: '(min-width: 577px) and (max-width: 768px)',
    width: 768,
    options: {
      worldOne: {
        xPosition: -20,
        yPosition: -80
      },
      meteroOne: {
        xPosition: 80,
        yPosition: -260
      },
      meteroTwo: {
        xPosition: 140,
        yPosition: -200
      },
      meteroThree: {
        xPosition: 130,
        yPosition: -320
      },
      worldTwo: {
        left: 600
      },
      spacemanWrap: {
        top: '30%',
        left: '25%'
      },
    }
  },
  {
    // phones
    size: '(min-width: 421px) and (max-width: 576px)',
    width: 576,
    options: {
      worldOne: {
        xPosition: 40,
        yPosition: -50
      },
      meteroOne: {
        xPosition: 90,
        yPosition: -200
      },
      meteroTwo: {
        xPosition: 110,
        yPosition: -160
      },
      meteroThree: {
        xPosition: 110,
        yPosition: -250
      },
      worldTwo: {
        left: 600
      },
      spacemanWrap: {
        top: '20%',
        left: '25%'
      },
    }
  },
  {
    // phones medium
    size: '(min-width: 331px) and (max-width: 420px)',
    width: 420,
    options: {
      worldOne: {
        xPosition: 40,
        yPosition: -50
      },
      meteroOne: {
        xPosition: 90,
        yPosition: -200
      },
      meteroTwo: {
        xPosition: 110,
        yPosition: -160
      },
      meteroThree: {
        xPosition: 110,
        yPosition: -250
      },
      worldTwo: {
        left: 650
      },
      spacemanWrap: {
        top: '20%',
        left: '15%'
      },
    }
  },
  {
    //small phones
    size: '(max-width: 330px)',
    width: 330,
    options: {
      worldOne: {
        xPosition: 40,
        yPosition: -50
      },
      meteroOne: {
        xPosition: 90,
        yPosition: -200
      },
      meteroTwo: {
        xPosition: 110,
        yPosition: -160
      },
      meteroThree: {
        xPosition: 110,
        yPosition: -235
      },
      worldTwo: {
        left: 600
      },
      spacemanWrap: {
        top: '15%',
        left: '15%'
      },
    }
  },
];