import React, { useState, useEffect, useCallback } from "react";
import Accounts from "./Accounts";
import Logo from "./Logo";
import Navigation from "./Navigation";
import { HeaderStyle } from "../styles/header.style";
import classNames from "classnames";

import {useLocation} from 'react-router-dom'

const Header = ({ activeNav, handleNavSelect }) => {
  const navHover =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317417/madwallet/navHover_tzxmjo.png";

  const [menuToggle, setMenuToggle] = useState(false);
  const [screenSize, setScreenSize] = useState("desktop");

  const handleResize = useCallback(() => {
    setScreenSize(handleLayoutMode);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleLayoutMode = () =>
    window.innerWidth > 768 ? "desktop" : "mobile";

  const handleMenuToggle = () => setMenuToggle(!menuToggle);

  const closeMenu = () => setMenuToggle(false);

  const location = useLocation()
  if (location.pathname !== '/') {
    return <div/>
  }

  return (
    <HeaderStyle
      bg={navHover}
      className={classNames("mdw__main-header", {
        'open': menuToggle && screenSize === "mobile",
      })}
    >
      <Logo section="header" />
      <Navigation
        activeNav={activeNav}
        screenSize={screenSize}
        menuToggle={menuToggle}
        onNavSelect={handleNavSelect}
        onNavClose={closeMenu}
      />
      <Accounts
        screenSize={screenSize}
        menuToggle={menuToggle}
        onToggleMenu={handleMenuToggle}
      />
    </HeaderStyle>
  );
};

export default Header;
