import styled from "styled-components"
import { flex, fontGrotesk } from "./mixins"

export const SearchBoxStyle = styled.div`
    width: 100%;
    height: 4rem;
    ${flex({direction: "row", align: "center"})}
    border: 2px solid rgba(157, 148, 175, 0.72);
    border-radius: 46px;
    padding: 0.3rem 1rem;
    box-sizing: border-box;
    

    img {
        margin: 0 1rem 0 0;
    }

    input {
        width: 100%;
        border: none;
        outline: none;
        ${fontGrotesk({size: "12px", weight: "700"})}
    }
`