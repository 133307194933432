import styled, {css} from 'styled-components';
import { flex, fontGrotesk, screenSize } from './mixins';


export const FormStyle = styled.form`
    width: 45rem;
    height: auto;
    background-color: white;
    border-radius: 8px;
    ${screenSize.mobile`
        width: 100%;
        height: 80%;
    `}

    h5 {
        margin: 3rem 0;
        ${fontGrotesk({size: "1.5rem"})}
    }

    main {
        .input-cont {
            width: 100%;
            margin: 0 0 2rem 0;
            height: 4rem;
            ${flex({direction: "row", align: "center", justify: "unset"})};
            ${screenSize.mobile`
                flex-direction: column;
                align-items: flex-start;
                gap: 0.5rem;
                margin-bottom: 3rem;
            `}

            label {
                width: 35%;
                ${screenSize.mobile`
                    width: 100%;
                `}
            }

            .input {
                width: 65%;
                height: 100%;
                padding: 5px 10px;
                border: 1px solid green;
                border-radius: 4px;
                text-transform: capitalize;
                ${fontGrotesk({size: "1.5rem"})}
                ${screenSize.mobile`
                    width: 100%;
                `}
            }
        }

        .form-btn-cont {
            width: 100%;
            margin-top: 5rem;
            ${flex({direction: "row", justify: "center", align: "center"})};

            .success {
                ${flex({direction: "row", justify: "center", align: "center", gap: "1rem"})};
                span {
                    color: green;
                }
            }
            .failure {
                ${flex({direction: "row", justify: "center", align: "center", gap: "1rem"})};
                span {
                    color: red;
                }
            }
        }
    }
`