import React, {useState} from 'react'
import { FormStyle } from '../styles/formStyle'
import { StyledButton } from '../styles/button.style'
import { button, buttonClicked } from './Home'
import { apiService } from '../utils/axios'
import { useMutation } from '@tanstack/react-query'

export const submitTransaction = async (data) => {
	const payload = { ...data };
	const response = await apiService.post({
		url: "/transactions",
		payload,
	});
	return response;
}



export const TransactionsForm = ({activeCampaigns, closeModal}) => {
  const [txnHash, setTxnHash] = useState("")
  const [campaignId, setCampaignId] = useState("")
  const [network, setNetwork] = useState("")
  const [status, setStatus] = useState(false)
  const [errMessg, setErrMssg] = useState("")

  const { isLoading, mutate, isError, } = useMutation(submitTransaction, {
    onError: (error) => {
      setErrMssg(error.data)
      setStatus(true)
      setDuration(1500)
    },
    onSuccess: (res) => {
      setCampaignId("")
      setNetwork("")
      setTxnHash("")
      // closeModal()
      setStatus(true)
      setDuration(2000)
    },
    retry: 0,
  });

  const setDuration = (time) => {
    setTimeout(() => {
      setStatus(false)
    }, time);
  }

  // const fakeApi = () => {
  //   setStatus(true)
  //   setDuration()
  // }

  // console.log("activeCampaigns sent-- ", activeCampaigns)

  const allNetworks = [
    {label: "BSC", value: "bsc"},
    {label: "Ethereum", value: "mainnet"},
    {label: "Polygon", value: "matic"},
  ]

 const disabled = !campaignId || !txnHash || !network


  return (
    <FormStyle>
        <h5>Please register your transaction details here</h5>
        <main>
          <div className='input-cont'>
            <label>Transaction Hash </label>
            <input 
              type="text"
              placeholder='Enter transaction hash'
              value={txnHash}
              onChange={(e) => setTxnHash(e.target.value)}
              className='input'
            />
          </div>

          <div className='input-cont'>
            <label>Campaigns</label>
            <select name="" id="" className='input' onChange={(e) => setCampaignId(e.target.value)}>
              <option value="">Select Campaign</option>
              {activeCampaigns.map((item, i) => (
                <option value={item.id} key={i}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className='input-cont'>
            <label>Network</label>
            <select name="" id="" className='input' onChange={(e) => setNetwork(e.target.value)}>
              <option value="">Select Network</option>
              {allNetworks.map((item, i) => (
                <option value={item.value} key={i}>{item.label}</option>
              ))}
            </select>
          </div>

          <div className="form-btn-cont">
            {status && !isError ? 
              (<div className='success'>
              <span>Transaction added</span>
                <img src="./leaderboard-images/check.png" alt="" />
              </div>
            ) : status && isError ? 
            (<div className='failure'>
              <span>{errMessg}</span>
                {/* <img src="./leaderboard-images/check.png" alt="" /> */}
              </div>
            )
            : (
            <StyledButton
              bg={buttonClicked ? buttonClicked : button}
              onClick={(e) => {
                e.preventDefault()
                mutate({txnHash, network, campaignId})
                // fakeApi()
              }}
              disabled={disabled || isLoading}
            > 
            {isLoading ? 
              (<span>Loading...</span>)
              :  (<span>Submit Transaction</span>)
            }
            </StyledButton>)

            }
          </div>
        </main>
    </FormStyle>
  )
}
