import React, { useEffect, useState } from 'react'
import {TableContainerStyle, TableEmptyStyle, TableStyle } from '../styles/tableStyle';


export const Table = ({tableColumns, tableRows, loading}) => {

    return (
      <TableContainerStyle>
          <TableStyle>
              <thead>
                  <tr className={"header_row"}>
                    {tableColumns?.map((column, i) => (
                      column?.component ? (
                        <th key={column.key}
                          className={"thead_row"}
                        >
                          {column?.component && column.label}
                        </th>
                      ) : (
                        <th key={column.key}
                        className={"thead_row"}
                        >
                          {column.label}
                        </th>
                      )
                    ))}
                  </tr>
              </thead>
  
              {Boolean(tableRows?.length) && !loading &&
                <tbody>
                    {tableRows?.map((row, i) => (
                        <tr key={i} className="tbody_row" >
                        {tableColumns?.map((column, i) =>
                          column?.component ? (
                            <td key={column.key} className='capitalize'>
                              {column.component(row[column?.key], row )}
                            </td>
                          ) : (
                            <td key={column.key} className={"table_data"}>
                              {row[column.key]}
                            </td>
                          )
                        )}
                        </tr>
                    ))}
                </tbody>
              }
          </TableStyle>
          
          <TableEmptyStyle>
          {loading && !tableRows?.length && (
            <div className={`non-data-cont ${!tableRows?.length && "empty-table"}`}>
              <p className='text-blue-600 text-[2rem]'>Loading...</p>
            </div>
          )}
          {loading && tableRows?.length && (
            <div className={`non-data-cont ${!tableRows?.length && "empty-table"}`}>
              <p className='text-green-600 text-[2rem]'>Loading campaigns...</p>
            </div>
          )}
          {!loading && !tableRows?.length && (
            <div className={`non-data-cont ${!tableRows?.length && "empty-table"}`}>
              <p className='text-red-400 text-[2rem]'>No ongoing campaigns at the moment</p>
            </div>
          )}
          </TableEmptyStyle>
      </TableContainerStyle>
    )
}
