import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'bd_cartoon_shoutregular';
  src: url(${props => props.ft1}) format('woff2'),
        url(${props => props.ft2}) format('woff'),
        url(${props => props.ft3}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trebuchet MS';
  src: url(${props => props.ft6}) format('woff2'),
        url(${props => props.ft5}) format('woff'),
        url(${props => props.ft4}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
    list-style: none;
  }
  
  html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  }
  
  body {
  min-height: 100vh;
  width: 100%;
  font-family: 'bd_cartoon_shoutregular','Rubik', sans-serif;
  overflow-x: hidden;
  overscroll-behavior: none;
  }

  p {
    font-family: 'Fira Sans', sans-serif;
  }
  
  .btn {
  display: block;
  text-transform: uppercase;
  }

section {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  
  &:not(.features) {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
}
    

@media screen and (max-width: 768px) {
  body.fixed {
    height: 100vh;
    overflow: hidden;
  }
}

@media screen and (min-width: 1400px){
  html {
    font-size: 75%;
  }
}

`;