import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const Navigation = ({ onNavSelect, onNavClose, activeNav, menuToggle, screenSize }) => {
  const navigate = useNavigate()

  const handleNavSelect = (e) => {
    onNavSelect(e);
    onNavClose()
  }

  return (
    <nav className={classNames('mdw__main-header--nav', { 'open': menuToggle })}>
      <div 
      className="nav-items"
      onClick={(e) => handleNavSelect(e)}
      >
        <button className={classNames('nav-links',{
          'activeLink': activeNav === 'landing'
        })} data-section="landing">Home</button>
      </div>
      <div className="nav-items"
      onClick={(e) => handleNavSelect(e)}
      >
        <button className={classNames('nav-links',{
          'activeLink': activeNav === 'features'
        })} data-section="features">Features</button>
      </div>
      <div className="nav-items"
      onClick={(e) => handleNavSelect(e)}
      >
        <button className={classNames('nav-links',{
          'activeLink': activeNav === 'madverse'
        })} data-section="madverse">Madverse</button>
      </div>
      <div className="nav-items"
      onClick={(e) => handleNavSelect(e)}
      >
        <button className={classNames('nav-links',{
          'activeLink': activeNav === 'download-madwallet'
        })} data-section="download-madwallet">Download</button>
      </div>
      <div className="nav-items"
      onClick={(e) => handleNavSelect(e)}
      >
        <button className={classNames('nav-links',{
          'activeLink': activeNav === 'community'
        })} data-section="community">Community</button>
      </div>

      <div className="nav-items"
        onClick={() => navigate("/leaderboard")}
      >
        <button className={classNames('nav-links',{
          'activeLink': activeNav === 'leaderboard'
        })} data-section="leaderboard">LeaderBoard</button>
      </div>

      { (screenSize === 'mobile') ?
          (<div className="nav-items">
            <span className="nav-links lang">En</span>
            </div>) : null
      }
    </nav>
  )
}

export default Navigation;
