import styled from "styled-components";
import { backgroundImage, flex, fontGrotesk, screenSize } from "./mixins";


export const TableContainerStyle = styled.div`
    width: 100%;
    height: 99.5%;
    max-height: 47rem;
    overflow: auto;

    ${screenSize.mobile`
        height: 98%;
        max-height: 40rem;
    `}

    .position-component {
        width: 100%;
        ${flex({justify: "center", align: "center"})};

        img {
            width: 5rem;
            height: 4rem;
        }
    }

    .position-num {
        width: 5rem;
        height: 4rem;
        ${flex({justify: "center", align: "center"})};
        ${backgroundImage({image: "/leaderboard-images/polygon.svg", position: "center", size: "100% 100%"})};
        ${fontGrotesk({size: "1.5rem", lineHeight: "2rem", weight: "700"})};
    }

    .wallet-address-component {
        ${flex({justify: "unset", align: "center"})};

        .copy-icon {
            cursor: pointer;
        }

        .copied-box {
            ${flex({direction: "column", justify: "center", align: "center"})}
        }
        .copied-text {
            color: #251940;
            border: 1px solid #251940;
            padding: 0 3px;
            border-radius: 2px;
            ${fontGrotesk({size: "10px", lineHeight: "14px", weight: "700"})};
        }
    }
`

export const TableStyle = styled.table`
    width: 100%;
    // height: 100%;
    border: none;
    border-collapse: collapse;
    overflow-y: auto;
    

    thead {
        width: 100%;
        height: 5rem;
        background-color: #EDE4FF;
       

        tr {
            width: 100%;
            padding: 0 0 0 0.5rem;
            box-sizing: border-box;

            th{
                padding: 1.5rem 0;
                text-align: left;
                ${fontGrotesk({size: "1.25rem", lineHeight: "1.43rem", color: "#251940", weight: "700"})};

                &:first-child { 
                    box-sizing: border-box;
                    text-align: center;
                }

                ${screenSize.small`
                    white-space: nowrap;
                    padding-right: 2rem;

                    &:last-child { 
                    padding-right: 1rem;
                }
                `}
            }
        }
    }

    tbody {
        width: 100%;
        height: 100%;

        tr {
            border-bottom: 1px solid #E0E0E0;

            td {
                padding: 1rem 0;
                ${fontGrotesk({size: "1.25rem", lineHeight: "1.43rem", weight: "700", color: "#251940"})};
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
`

export const TableEmptyStyle = styled.div`
    width: 100%;
    ${flex({direction: "column", align: "center", justify: "center"})};

    .non-data-cont {
        width: 100%;
        p {
            text-align: center;
            ${fontGrotesk({size: "2rem", weight: 700, })}
        }
    }

    .empty-table {
        height: 10rem;
        ${flex({justify: "center", align: "center"})};
        /* background-color: green; */
        color: #9C9C9C;
    }
`