import styled, {css} from 'styled-components';
import { fontGrotesk, screenSize, flex } from './mixins';

export const ModalStyle = styled.div`
    width: 100vw;
    height: 100vh;
    ${flex({direction: "column", align: "center", justify: "center"})};
        @include flex(column, center, center);
        z-index: 5000;
        position: fixed;
        top: 0;
        left: 0;
    background: rgba(38, 40, 62, 0.6);
    backdrop-filter: blur(3px);
    overflow: hidden;
    box-sizing: border-box;
    ${props => props.display ? css`
        display: block;
    ` : css`
        display: none;
    `}
        

    .modal-cont-show {
        width: 100%;
        height: 100%;
        ${flex({direction: "column", align: "center", justify: "center"})};
    }

    .content-cont {
            min-height: 25vh;
            max-height: 90vh;
            z-index: 100;
            background-color: white;
            padding: 2rem 1.5rem;
            border: 2.11639px solid #FFFFFF;
            box-shadow: 0px 27.5131px 118.518px rgba(136, 122, 166, 0.12);
            border-radius: 10px;
            ${flex({direction: "column", align: "unset", justify: "unset"})};
            overflow-y: auto;
        
    }

    .modal-title-cont {
        width: 100%;
        ${flex({direction: "row", align: "center", justify: "space-between"})};
        margin-bottom: 2rem;
    }

    .modal-title {
        justify-self: flex-start;
        ${fontGrotesk({size: "2rem", weight: "bold"})};
        color: #28166F;
    }

    .modal-close-btn {
        cursor: pointer;
    }

    .closeModal {
        cursor: pointer;
        justify-self: flex-end;
        padding: 0.5rem;
        border-radius: 6px;
    } 

    /* .modal-cont-hidden {
        display: none;
    } */
`