import React, { useState, useRef, useEffect } from "react";
import gsap from "gsap";
import classNames from "classnames";
import { MadverseStyle } from "../styles/madverse.style";
import useMediaQuery from "../hooks/useMediaQuery";

const Madverse = () => {
  const madverse =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317476/madwallet/madverse_zvfsx1.png";
  const madverseMb =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317477/madwallet/madverseMb_u3ckkl.png";
  const comingSoon =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317473/madwallet/comingSoon_epublu.png";
  const madverseTree1 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317473/madwallet/madverseTree1_mkvhw1.png";
  const madverseTree2 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317479/madwallet/madverseTree2_tcpdaa.png";
  const madverseTree3 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317474/madwallet/madverseTree3_vhlzga.png";
  const madverseTree4 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317474/madwallet/madverseTree4_i5zu2i.png";
  const madversBg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317473/madwallet/madversBg_qotjkg.jpg";
  const sunRaysBg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317475/madwallet/sunRaysBg_pqwwkx.png";

  const [nightMode, setNightMode] = useState(true);

  const rootRef = useRef(null);
  const bgImg = useRef(null);
  const daylight = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      // define x and y positions for mouse or touch
      let x = e.touches ? e.touches[0].clientX : e.clientX;

      let y = e.touches ? e.touches[0].clientY : e.clientY;

      // Get the offset of the mouse position from the left and top respectively
      let left = window.innerWidth / 2;
      let top = window.innerHeight / 2;

      let offsetLeft = -(x - left) / (left / 10) - 10;
      let offsetTop = -(y - top) / (top / 10) - 10;

      gsap.to(bgImg.current, {
        duration: 1,
        top: `${offsetTop}%`,
        left: `${offsetLeft}%`,
      });
    };

    rootRef.current.addEventListener("mousemove", handleMouseMove);
    rootRef.current.addEventListener("touchstart", handleMouseMove);
    rootRef.current.addEventListener("touchmove", handleMouseMove);
  }, []);

  useEffect(() => {
    let intervalId = setInterval(() => {
      setNightMode(!nightMode);
    }, 15000);
    return () => clearInterval(intervalId);
  }, [nightMode]);

  const matches = useMediaQuery("(max-width: 768px)");

  return (
    <MadverseStyle
      id="madverse"
      bg={madversBg}
      sunrays={sunRaysBg}
      ref={rootRef}
    >
      <div className="madverse-bg">
        <div className="madverse--img" ref={bgImg}>
          <img src={madversBg} alt="bg" />
        </div>
      </div>
      {/* trees */}
      <div className="madverse-tree-container">
        <div className="madverse-tree tree1">
          <img src={madverseTree1} alt="tree one" />
        </div>
        <div className="madverse-tree tree2">
          <img src={madverseTree2} alt="tree two" />
        </div>
        <div className="madverse-tree tree3">
          <img src={madverseTree3} alt="tree three" />
        </div>
        <div className="madverse-tree tree4">
          <img src={madverseTree4} alt="tree four" />
        </div>
      </div>
      <div className="section__content">
        <div className="heading">
          <img src={matches ? madverseMb : madverse} alt="madverse head" />
          <p className="section__content--text">
          Madverse" simply means the MADWallet and the Metaverse. The MadWallet is compatible with VR. Users can interface with and use this wallet in the virtual reality space to purchase items (NFTs) and spend their crypto across chains without restrictions.
          </p>
        </div>
        <span className="section__content--info">
          <img src={comingSoon} alt="coming soon" />
        </span>
      </div>
      <div
        className={classNames("madverse-sun-rays", { nightMode: nightMode })}
        ref={daylight}
      ></div>
    </MadverseStyle>
  );
};

export default Madverse;
