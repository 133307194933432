import React, {useState, useEffect} from 'react'
import { ModalStyle } from '../styles/modalStyle'

export const Modal = ({modalChild, modalOpen, modalClose, title, closeIcon, modalClass}) => {
    
    // useEffect(() => {
    //     setModal(modalOpen)
    // }, [modalOpen])

    return (
        <ModalStyle display={modalOpen}>
        <div
			className={modalOpen ? "modal-cont-show" : "modal-cont-hidden"}
			onClick={() => {
                modalClose && modalClose()
            }}
		>
			<div
				className={"content-cont"}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="modal-title-cont">
					<span className='modal-title'>
						{title}
					</span>
					<img src={"/leaderboard-images/cancel.svg"} alt="" 
                        className='modal-close-btn'
                        onClick={() => modalClose && modalClose()} 
					/>
				</div>
				<div className={`modal-child-cont`}>
					{modalOpen && modalChild}
				</div>
			</div>
            </div>
		</ModalStyle>
    )
}
