import styled from 'styled-components';

export const LandingStyle = styled.section`
    position: relative;
    background-image: url(${(props) => props.bg});
    background-size: 130% 130%;
    background-attachment: fixed;
    background-position: center;

    @media screen and (max-width: 992px) {
        background-attachment: scroll;
    }


    .title {
        font-size: 4rem;
        letter-spacing: .2px;
        text-transform: uppercase;

        @media screen and (max-width: 1200px) {
            font-size: 3.5rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 3rem;
        }

        @media screen and (max-width: 576px) {
            margin-top: -20rem;
            width: 70%;
            line-height: 1.5;
            font-size: 2rem;
            text-align: center;

            span {
                font-size: 3rem;
            }
        }
    }

    p {
        font-size: 1.5rem;
        margin-bottom: 4rem;

        @media screen and (max-width: 1200px) {
            padding: .5rem 1rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.3rem;
        }
        
        @media screen and (max-width: 768px) {
            margin-top: 2rem;
            width: 45%;
            text-align: center;
        }

        @media screen and (max-width: 378px) {
            width: 55%;
            margin-bottom: 2rem;
        }
    }

    .intro-text {
        position: absolute;
        top: 50%;
        text-align: center;
        width: 78%;
        font-weight: 400;
        font-size: 1.2rem;
        background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;


        @media screen and (max-width: 1200px) {
            width: 90%;
            margin-left: 1rem;
            font-size: 1.2rem;
        }

        @media screen and (max-width: 768px) {
            width: 70%;
            margin-left: 1rem;
            font-size: 1.3rem;
        }

        @media screen and (max-width: 576px) {
            top: 50%;
            width: 80%;
        }

        @media screen and (max-width: 378px) and (max-height: 700px) {
            top: 45%;
            width: 85%;
        }

        @media screen and (min-width: 320px) and (max-height: 700px) {
            top: 50%;
            font-size: 1rem;
        }
        @media screen and (max-width: 340px){
            top: 41%;
            font-size: 1rem;
        }

    }
}

.mdw-world,
.meteros,
.metero,
.space-world {
    width: 50rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mdw-world {
    @media screen and (max-width: 768px) {
        width: 40rem;
    }

    @media screen and (max-width: 576px) {
        top: 45%;
        left: 115%;
    }

    @media screen and (max-width: 378px) {
       left: 125%;
    }
    
}

.metero2 {
    width: 12rem;
    top: 65%;
    left: 18%;

    @media screen and (max-width: 992px) {
        width: 10rem;
    }

    @media screen and (max-width: 768px) {
        width: 8rem;
    }
}
    
.metero1 {
    width: 15rem;
    top: 75%;
    left: 28%;

    @media screen and (max-width: 992px) {
        width: 13rem;
    }

    @media screen and (max-width: 768px) {
        width: 11rem;
    }
}
    
.metero3 {
    width: 8.5rem;
    top: 82%;
    left: 12%;

    @media screen and (min-width: 1400px) {
        left: 20rem;
    }

    @media screen and (min-width: 1700px) {
        left: 30rem;
    }

    @media screen and (max-width: 992px) {
        width: 6.5rem;
    }

    @media screen and (max-width: 768px) {
        width: 5rem;
    }
}
    
.spaceship {
    width: 15rem;
    top: 30%;
    left: 20%;

    @media screen and (max-width: 992px) {
        width: 12rem;
        top: 25%;
        left: 15%;
    }

    @media screen and (max-width: 768px) {
        width: 10rem;
    }
}
    
.metero {
    width: 15rem;
    top: 25%;
    left: 83%;

    @media screen and (max-width: 992px) {
        width: 12rem;
    }

    @media screen and (max-width: 768px) {
        top: 50%;
    }
}
    
.mdw-world img,
.meteros img,
.spaceship img,
.astraunut img,
.metero img,
.world-two img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.content {
    position: absolute;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
    top: 64%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e4e4e4;
}

.interactive {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1200px) {
        padding: .5rem 1rem;
    }

    @media screen and (max-width: 576px) {
       flex-direction: column;
       margin-top: 5rem;
       height: 22%;
       justify-content: space-between;

        @media screen and (max-width: 378px) and (max-height: 700px) {
           height: 26%;
        }

        @media screen and (min-width: 330px) and (max-height: 700px) {
            height: 22%;
        }
    }
}

.intro-message {
    position: absolute;
    top: 50%;
    left:5%;
    z-index: 10;
    width: 40%;
    height: auto;
    color: #A4A4A4;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 150ms ease-in;

    @media screen and (min-width: 1400px) {
        left: 10%;
    }

    @media screen and (min-width: 1700px) {
        left: 12%;
    }

    @media screen and (max-width: 1200px) {
        width: 50%;
    }

    @media screen and (max-width: 992px) {
        width: 60%;
    }

    @media screen and (max-width: 768px) {
        top: 35%;
        left:2%;
        flex-direction: column;
        width: 70%;
    }

    @media screen and (max-width: 576px) {
        width: 90%;
        left: 5%;
    }

    @media screen and (max-width: 378px) {
       width: 95%;
       left: 2.5%;
    }

    & img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
}

.spaceship,
.spaceman-wrapper,
.astraunut,
.world-two {
    position: absolute;
}

.space-world {
    width: 180rem;
    height: 100rem;
    left: 100%;

    @media screen and (max-width: 768px) {
        width: 160rem;
    }
}
    
.spaceman-wrapper {
    width: 90rem;
    height: 70rem;
    top: 55%;
    left: 20%;

    @media screen and (max-width: 768px) {
        width: 80rem;
    }
}
    
.astraunut {
    width: 80rem;
    left: 30%;
    top: 0;

    @media screen and (max-width: 992px) {
        width: 60rem;
        left: 40%;
        top: 5%;
    }

    @media screen and (max-width: 768px) {
        left: 35%;
    }

    @media screen and (max-width: 576px) {
        left: 80%;
    }
}
    
.world-two {
    width: 120rem;
    left: 300rem;
    top: 12%;
    transform: scale(4);

    @media screen and (min-width: 1650px) {
        left: 350rem;
        width: 160rem;
        top: 20%;
        transform: scale(3);
    }

    @media screen and (max-width: 1200px) {
        width: 125rem;
    }

    @media screen and (max-width: 768px) {
        width: 80rem;
        top: 20%;
    }

`;