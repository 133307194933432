    export const savings =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317404/madwallet/savings_rd9h8p.png";
    export const savingsMsgMb = 
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317401/madwallet/savingsMsgMb_afurxv.png";
    export const savingsMsg = 
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317404/madwallet/savingsMsg_wkswlu.png";
  
  export const crosschain =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317395/madwallet/crosschain_xz9sfv.png";
  export const crosschainMsgMb =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317398/madwallet/crosschainMsgMb_uzjqoo.png";
  export const crosschainMsg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317395/madwallet/crosschainMsg_hcb4xh.png";
  
  export const marketplace =
      "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317404/madwallet/marketplace_rrqvuk.png";
  export const marketplaceMsgMb =
      "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317399/madwallet/marketplaceMsgMb_ps90h9.png";
  export const marketplaceMsg =
      "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317398/madwallet/marketplaceMsg_vtnzor.png";
    
  export const customization =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317404/madwallet/customization_u1iwzc.png";
  export const customizationMsgMb =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317397/madwallet/customizationMsgMb_khsqpp.png";
  export const customizationMsg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317396/madwallet/customizationMsg_tzadt6.png";
  
  export const FeaturesBg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317398/madwallet/FeaturesBg_i7qqv3.jpg";

  export const featuresMobileNav =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317398/madwallet/featuresMobileNav_lxadiy.png";
  
  
    export const tabs = [
      {
        name: "savings",
        label: "DeFi Savings",
      },
      {
        name: "cross-chain",
        label: "CROSS-CHAiN BRiDGE",
      },
      {
        name: "market",
        label: "NFT Marketplace",
      },
      {
        name: "customization",
        label: "customization",
      },
      {
        name: "rwa-aggregator",
        label: "RWA Aggregator",
      },
    ];

    