import React from "react"
import { useState } from "react";
import { copyToClipboard, truncateAddress } from ".";

export const WalletAddressComp = (data) => {
    const [copied, setCopied] = useState(false)

    const setDuration = (time, timeAction) => {
        setTimeout(() => {
            setCopied(false)
        }, time);
    }

    return (
        <div className='wallet-address-component'>
            <span>{truncateAddress(data)}</span>
            {copied ? (
                <div className="copied-box">
                    <img src="./leaderboard-images/check.png" alt="" className="copied-img" />
                    {/* <p className="copied-text">Copied</p> */}
                </div>
            ) 
            : (<img src="./leaderboard-images/Copy.svg" alt="copy" 
                onClick={() => {
                    copyToClipboard(data)
                    setDuration(1500, setCopied(true))
                }}
                className="copy-icon"
            />)}
        </div>
    )
}

export const PositionComponent = (data) => {
    return (
        <div className='position-component'>
            {
                data == 1 ? <img src="./leaderboard-images/first.svg" alt="" />
                : data == 2 ? <img src="./leaderboard-images/second.svg" alt="" />
                : data == 3 ? <img src="./leaderboard-images/third.svg" alt="" />
                : <p className="position-num">{data}</p>
            }
        </div>
    )
}


export const tradingCampaignTableHeader = [
    {key: "position", label: "Position", component: PositionComponent},
    {key: "walletAddress", label: "Wallet Address", component: WalletAddressComp},
    {key: "txVolume", label: "Tx Volume"},
    {key: "noOfTrx", label: "No. of Trx"},
    {key: "score", label: "Points"},
]

export const mediaCampaignTableHeader = [
    {key: "position", label: "Position", component: PositionComponent},
    {key: "walletAddress", label: "Wallet Address", component: WalletAddressComp},
    {key: "articles", label: "Articles"},
    {key: "socialPost", label: "No. of Socail Post"},
    {key: "videos", label: "Videos"},
    {key: "score", label: "Points"},
]

export const tableDummyData = [
    {position: "1", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "2", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "3", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "4", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "5", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "6", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "7", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "8", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "9", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
    {position: "10", walletAddress: "0xu36gd6ene7dbey4bnd8j34n46be6dg", txVolume: 2356, noOfTrx: 8349, score: 366},
]