import styled from 'styled-components';

export const NewsStyle = styled.section`
    position: relative;
    background-image: url(${props => props.bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .newsSlide {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;      
        width: 100vw;
        height: 100vh;
        opacity: 0;
        transition: opacity 800ms ease-out;

        &.active {
            opacity: 1;
            z-index: 21;
        }
    }
    
    .newsletter__content {
        position: absolute;
        top: 34%;
        left: 30%;
        width: 45%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;

        @media screen and (max-width: 1200px) {
            width: 50%;
        }

        @media screen and (max-width: 768px) {
            width: 65%;
            left: 50%;
            top: 40%;
        }

        @media screen and (max-width: 365px) {
            width: 75%;
        }

        @media screen and (max-width: 350px) and (max-height: 500px) {
            width: 85%;
            top: 30%;
        }

        &--text {
            position: absolute;
            width: 90%;
            text-align: center;
            top: 40%;
            font-size: 1.5rem;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 3rem;
            padding: 2rem;
            background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;

            @media screen and (max-width: 992px) {
                font-size: 1.2rem;
                top: 22%;
                line-height: 1.6;
            }

            @media screen and (max-width: 768px) {
                top: 20%;
            }

            @media screen and (max-width: 768px) and (max-height: 1000px) {
                top: 15%;
            }

            @media screen and (max-width: 576px) {
                top: 20%;
                width: 95%;
            }

            @media screen and (max-width: 490px) {
                top: 10%;
                width: 100%;
            }

             
            @media screen and (max-width: 378px) {
                top: 5%;
                line-height: 1.4;
                width: 100%;
            }

            @media screen and (max-width: 378px) and (max-height: 700px) {
                top: 10%;
                line-height: 1.6;
                width: 100%;
            }
           
            @media screen and (max-width: 378px) and (max-height: 520px) {
                line-height: 1.4;
               font-size: 1rem;
               top: 3%;
            }
        }
    }
    
    .newsletter-message {
        position: relative;
        margin-top: 10%;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            width: 60%;
            margin-top: 0;
        }

        @media screen and (max-width: 768px) and (max-height: 1000px) {
            width: 85%;
        }

        @media screen and (max-width: 576px) {
            margin-top: 5rem;
            width: 90%;
        }

        @media screen and (max-width: 576px) and (max-height: 900px){
            margin-top: -2rem;
        }

        @media screen and (max-width: 490px) {
            margin-top: -3rem;
            width: 100%;
        }

        @media screen and (max-width: 490px) and (max-height: 900px) {
            width: 95%;
            margin-top: -8rem;
        }

        @media screen and (max-width: 490px) and (max-height: 800px) {
            width: 95%;
            margin-top: -8rem;
        }

        @media screen and (max-width: 400px) and (max-height: 700px) {
            width: 95%;
            margin-top: -10rem;
        }

        @media screen and (max-width: 378px) and (max-height: 520px) {
            width: 78%;
            margin-top: -7rem;
        }
        
        & img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }
    
    .newsletter__interactive {
        position: absolute;
        top: 65%;
        left: 30%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 20;

        & > button {
            width: 25rem;
            top: 3rem;
            font-size: 1.1rem;
        }
        
        & .browsers{
            display: flex;
            width: 41rem;
            margin-inline: auto; 
            justify-content: space-evenly;
            align-items: center;
            margin-bottom: -10rem;

            & > span >img{
                width: 3.5rem;
            }
        }

        @media screen and (max-width: 992px) {
            bottom: 25%;
        }

        @media screen and (max-width: 768px) {
            left: 50%;
            bottom: 12%;
        }

        @media screen and (max-width: 576px) {
            margin-top: 10rem;
            width: 100%;
        }
        
        & .platforms {
            margin-top: 6rem;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: capitalize;
            background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;

            @media screen and (max-width: 992px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 576px) {
                font-size: 1.5rem;
            }

            @media screen and (max-width: 378px) {
                font-size: 1.2rem;
            }

            @media screen and (max-width: 378px) and (max-height: 520px) {
                font-size: 1.2rem;
            }

        }
    }

    .holes {
            position: absolute;
            bottom: 3rem;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 22;


            & div {
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                background-color: #999;
                margin: 1rem;
                cursor: pointer;

                @media screen and (max-width: 992px) {
                    width: 1.5rem;
                    height: 1.5rem;
                }

                @media screen and (max-width: 378px) {
                    margin: .7rem;
                    width: 1rem;
                    height: 1rem;
                }


                &.active {
                    background-color: #222;
                }
            }
        }

    .newsletter-img {
        position: absolute;
        top: 50%;
        right: 10%;
        width: 30%;
        height: auto;
        transform: translateY(-50%);

        @media screen and (min-width: 1400px) {
            right: 5%;
            width: 30%;
        }

        @media screen and (max-width: 1300px) {
            right: 8%;
        }

        @media screen and (max-width: 1200px) {
            width: 32%;
            right: 3%;
        }


        @media screen and (max-width: 992px) {
            top: 45%;
            width: 30%;
            right: 5%;
        }

        @media screen and (max-width: 768px) {
            top: 65%;
            left: 40%;
            z-index: 10;
        }

        @media screen and (max-width: 768px) and (max-height: 1000px) {
            width: 25rem;
        }

        @media screen and (max-width: 576px) {
            top: 65%;
        }

        @media screen and (max-width: 490px) {
            top: 68%;
            left: 30%;
        }

        @media screen and (max-width: 378px) and (max-height: 700px) {
            width: 62%;
            top: 65%;
            left: 35%;
        }

        @media screen and (max-width: 340px) and (max-height: 700px) {
            width: 60%;
            top: 63%;
            left: 30%;
        }

        @media screen and (max-width: 378px) and (max-height: 520px) {
            width: 45%;
        }

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

`;