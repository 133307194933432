import React, { useEffect, useState } from 'react'
import { LeaderboardStyle } from '../styles/leaderboard.style'
import Logo from "../components/Logo"
import { CampaignCard, Faqs, SearchBox, Tab, Table, TransactionsForm } from '../components'
import { CampaignButtonStyle } from '../styles/button.style'
import { showToast, tableDummyData, tradingCampaignTableHeader, mediaCampaignTableHeader } from '../utils'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom'
import {useQuery} from "@tanstack/react-query"
import { apiService } from '../utils/axios'
import { useDebounce } from "use-debounce";
import { Modal } from '../components/Modal'

export const Leaderboard = () => {
  const navigate = useNavigate()

  const [allTradingCampaignTypes, setAllTradingCampaignTypes] = useState([])
  const [allMediaCampaignTypes, setAllMediaCampaignTypes] = useState([])
  const [activeCampaign, setActiveCampaign] = useState("")
  const [tradingActiveCampaign, setTradingActiveCampaign] = useState("")
  const [mediaActiveCampaign, setMediaActiveCampaign] = useState("")
  const [tab, setTab] = useState("trading")
  const [tableData, setTableData] = useState([])
  const [mediaTableData, setMediaTableData] = useState([])
  const [mediaData, setMediaData] = useState([])
  const [search, setSearch] = useState("")
  const [modal, setModal] = useState(false)

  const [debounceValue] = useDebounce(search, 500)

  // useEffect(() => {
  //   searchAddress(debounceValue)
  // }, [debounceValue])

  // const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  
  const {data: allTradingCampaignData, isError: allTradingCampaignError, isLoading: allTradingCampaignLoading} 
  = useQuery({
    queryKey: ["all-trading-campaigns-types"],
    queryFn: async () => await apiService.get(`/campaigns/active/1/57`),
    onSuccess: (res) => {
      setAllTradingCampaignTypes(res?.data?.data)
      // setActiveCampaign(res?.data?.data[0]?.id)
      setTradingActiveCampaign(res?.data?.data[0]?.id)
    },
    onError: (error) => showToast(error?.message, "error"),
    // enabled: !!(!allTradingCampaignTypes?.length),
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  const {data: allMediaCampaignData, isError: allMediaCampaignError, isLoading: allMediaCampaignLoading} 
  = useQuery({
    queryKey: ["all-media-campaigns-types"],
    queryFn: async () => await apiService.get(`/excelCampaign/active/1/55`),
    onSuccess: (res) => {
      setAllMediaCampaignTypes(res?.data?.data)
      // setActiveCampaign(res?.data?.data[0]?.id)
      setMediaActiveCampaign((res?.data?.data[0]?.id))
    },
    onError: (error) => showToast(error?.message, "error"),
    staleTime: Infinity,
    cacheTime: Infinity,
  })


  const {data: singleTradingCampaignData, isError: singleTradingCampaignError, isLoading: singleTradingCampaignLoading} = useQuery({
    queryKey: ["single-trading-campaign-table-data", tradingActiveCampaign, tab],
    queryFn: async () => await apiService.get(`/leaderboard/campaigns/${tradingActiveCampaign}/1/103`),
    onSuccess: (res) => {},
    onError: (error) => showToast(error?.message, "error"),
    enabled: tradingActiveCampaign !== "",  // !!(allTradingCampaignTypes?.length > 0),
    staleTime: 5000,
    cacheTime: 10,
    refetch: allTradingCampaignTypes?.length > 0 && true
  })

  const {data: singleMedaiCampaignData, isError: singleMediaCampaignError, isLoading: singleMediaCampaignLoading} = useQuery({
    queryKey: ["single-media-campaign-table-data", mediaActiveCampaign, tab],
    queryFn: async () => await apiService.get(`/excelCampaign/leaderboard/${mediaActiveCampaign}`),
    onSuccess: (res) => {},
    onError: (error) => showToast(error?.message, "error"),
    enabled: mediaActiveCampaign !== "",  //  !!(allMediaCampaignTypes?.length > 0),
    staleTime: 5000,
    cacheTime: 10,
    refetch: allMediaCampaignTypes?.length > 0 && true
  })

  

  const sortData = (arr) => arr?.data?.sort((a, b) => b.points - a.points)


  // const searchAddress = (keyword) => {
  //   console.log("keyword== ", keyword)
  //   console.log("data---- ", tableData)
  //   const searchAdd = singleTradingCampaignData?.data?.data?.filter(item => {
  //     return item?.address?.toString().includes(keyword)
  //   })
  //   // console.log("found== ", foundSearch)
  //   setTableData(state => searchAdd)
  //   console.log("searched items== ", searchAdd)
  // }

  const decimalPoint = (val) => {
    if (String(val)?.split(".")[1]?.length > 2) return val.toFixed(2)
    else return val
  }



  return (
    <>
    <LeaderboardStyle >
      <div className='leaderboard-container' id="leaderboard">
        <main className='ldb-top' >
          <div className="back-cont" onClick={() => navigate("/")}>
            <img src="./leaderboard-images/Out.svg" alt="" />
            <span>Back to home</span>
          </div>
          <div className="top">
            <div className="logo-div"  > 
              <Logo section={"header"} onClick={() => navigate("/")} /> 
            </div>
            <p className='ldb-main-text'>MadWallet Reward Campaigns</p>
            <div className="campaign-slides">
              {/* <Slider {...settings}>
              {[1, 2, 3, 4, 5, 6].map((item, i) => (
                <CampaignCard
                  key={i} 
                  id={i}
                />
              ))}
              </Slider> */}
              <a href="https://medium.com/xendfinance/madwallet-cmc-launch-campaign-terms-conditions-342d6ef43292" 
                target="_blank" rel="noopener noreferrer"
                className='ldb-campaign-card'
              >
                <img src="./campaign-banners/FreeTokenAredrop.png" alt="" />
              </a>
              <a href="https://galxe.com/XendFinance/campaign/GCZUvUymWh" 
                target="_blank" rel="noopener noreferrer"
                className='ldb-campaign-card'
              >
                <img src="./campaign-banners/Gax x MADWallet.png" alt="" />
              </a>
            </div>
          </div>
          <div className="ldb-curve">
            <h2 className='MadRanks'>MadRanks</h2>
            <p>
              <span className='participants'>Total Participants</span>
              <span className='total'>
                {tab === "trading" ? singleTradingCampaignData?.data?.length : singleMedaiCampaignData?.data?.length}
              </span>
            </p>
          </div>
        </main>

        <main className="ldb-down">
          <div className="down-top">
            <div className="down-left">
              <div className="top-left">
                <h4 className='top-scorer'>Top Score</h4>
                <img src="/leaderboard-images/21205976_8 1.png" alt="" className='ldb-top-scorer-img' />
                <h4 className='overall-rank'>Overall Rank #1</h4>
                {tab === "trading" && sortData(singleTradingCampaignData) ? 
                <p className="overall-score">
                  {`${decimalPoint(sortData(singleTradingCampaignData)[0]?.point)} pt.`}
                </p>
                : tab === "media" && sortData(singleMedaiCampaignData) ? 
                <p className="overall-score">
                  {`${decimalPoint(sortData(singleMedaiCampaignData)[0]?.points)} pt.`}
                </p>
                : <p className="overall-score">{`0`}</p>
                }
                
              </div>
            </div>

            <div className="down-right">
              <div className="ldb-wrapper">
                <div className="ldb-table-top">
                  <div className="ldb-table-header">
                    <div className="ldb-table-header-left">
                      <h3 className="leaderboard-table-title">Leaderboard</h3>
                      <div className='ldb-update-info'>
                        <p className='ldb-update-freq'>Updated every 24hrs</p>
                      </div>
                    </div>
                    <div className="search-box">
                      {/* <SearchBox value={search} setValue={setSearch} placeholder="Search by wallet address" /> */}
                      <CampaignButtonStyle
                        bg={"#251940"}
                        color="white"
                        border="transparent"
                        onClick={() => setModal(true)}
                      >
                        Register Transaction
                      </CampaignButtonStyle>
                    </div>
                  </div>

                  <div className='ldb_tab-cont'>
                    <Tab text={"Trading Champions"} 
                      active={tab} id={"trading"} 
                      onClick={() => {
                        setTab("trading")
                        setActiveCampaign(allTradingCampaignTypes[0]?.id)
                      }}
                    />
                    <Tab text={"media Champions"} active={tab} id={"media"} onClick={() => {
                        setTab("media")
                        setActiveCampaign(allMediaCampaignTypes[0]?.id)
                      }} 
                    />
                  </div>
                 
                  <div className="filter-box">
                    {((tab === "trading" && tradingActiveCampaign !== "") ? allTradingCampaignData?.data?.data : allMediaCampaignData?.data?.data)?.map((item) => (
                      <CampaignButtonStyle key={item?.id}
                        bg={(tab === "trading" && tradingActiveCampaign === item?.id) || (tab === "media" && mediaActiveCampaign === item?.id) ? "#251940" : ""}
                        color={(tab === "trading" && tradingActiveCampaign === item?.id) || (tab === "media" && mediaActiveCampaign === item?.id) ? "#BCA9E6" : "#443861"}
                        border={(tab === "trading" && tradingActiveCampaign === item?.id) || (tab === "media" && mediaActiveCampaign === item?.id) ? "transparent" : "grey"}
                        // active={(tab === "trading" && tradingActiveCampaign === item?.id) || (tab === "media" && mediaActiveCampaign === item?.id) ? activeCampaign : null}
                        onClick={() => {
                          // setActiveCampaign(item?.id)
                          if (tab === "trading") setTradingActiveCampaign(item?.id)
                          else setMediaActiveCampaign(item?.id)
                        }}
                        id={item?.id}
                        activeCampaign={activeCampaign}
                      >
                        {item?.name || item?.title}
                      </CampaignButtonStyle>
                    ))}
                  </div>
                </div>

                <div className="ldb-table-cont">
                  {tab === "trading" && 
                  <Table 
                    tableColumns={tradingCampaignTableHeader}
                    tableRows={sortData(singleTradingCampaignData)?.map((item, i) => ({
                      ...item,
                      position: i + 1,
                      walletAddress: item?.address,
                      txVolume: decimalPoint(item?.txn_volume),
                      noOfTrx: item?.txn_count,
                      score: decimalPoint(item?.point)
                    }))} 
                    loading={singleTradingCampaignLoading }
                  />}

                  {tab === "media" && 
                  <Table 
                    tableColumns={mediaCampaignTableHeader}
                    tableRows={sortData(singleMedaiCampaignData)?.map((item, i) => ({
                      ...item,
                      position: i + 1,
                      walletAddress: item?.wallet_address,
                      articles: item?.no_of_articles,
                      socialPost: item?.no_of_social_posts,
                      videos: item?.no_of_videos,
                      score: decimalPoint(item?.points)
                    }))}
                    loading={singleMediaCampaignLoading}
                  />}
                </div>
              </div>
            </div>
          </div>

          <div className="down-bottom">
              <Faqs />
          </div>

          <p className="ldb-footer">
            Copyright &copy; 2023 MadWallet. All Rights Reserved
          </p>
        </main>

      </div>
    </LeaderboardStyle>

    <Modal
        modalOpen={modal}
        modalClose={() => setModal(false)}
        modalChild={
          <TransactionsForm
            activeCampaigns={allTradingCampaignData?.data?.data}
            closeModal={() => setModal(false)}
            // allTradingCampaignData?.data?.data
          />
        }
        title="Transactions Details Form"
      />
    </>
  )
}

// const settings = {
//   className: "center-slide",
//   centerMode: true,
//   infinite: true,
//   centerPadding: "60px",
//   slidesToShow: 3,
//   speed: 5000,
//   slidesToScroll: 1,
//   autoplay: true,

//   responsive: [
//     {
//       breakpoint: 991,
//       settings: {
//         centerMode: true,
//           infinite: true,
//           centerPadding: "20px",
//           slidesToShow: 3,
//           speed: 5000,
//         slidesToScroll: 1,
//         autoplay: true,
//       }
//     },
//     {
//       breakpoint: 768,
//       settings: {
//         centerMode: true,
//           infinite: true,
//           centerPadding: "20px",
//           slidesToShow: 2,
//           speed: 5000,
//         slidesToScroll: 1,
//         autoplay: true,
//       }
//     },
//     {
//       breakpoint: 576,
//         settings: {
//           centerMode: true,
//           infinite: true,
//           centerPadding: "20px",
//           slidesToShow: 1,
//           speed: 5000,
//           slidesToScroll: 1,
//           autoplay: true,
//         }
//     },
    
//   ]
// };

