import styled from 'styled-components';

export const CommunityStyle = styled.section`
    position: relative;
    background-image: url(${props => props.bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .community__content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;

        @media screen and (max-width: 1200px) {
            width: 90%;
        }

        @media screen and (max-width: 768px) {
            width: 80%;
        }

        @media screen and (max-width: 576px) {
            width: 90%;
        }

        &--container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 70%;
            height: auto;

            @media screen and (max-width: 1200px) {
                width: 80%;
            }

            @media screen and (max-width: 992px) {
                width: 100%;
            }

            & img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }

        &--text {
            position: absolute;
            top: 25%;
            text-align: center;
            width: 60%;
            font-size: 1.4rem;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 5rem;
            padding: 2rem;
            background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;

            @media screen and (min-width: 1600px) {
                top: 30%;
            }

            @media screen and (max-width: 1300px) {
                top: 20%;
            }

            @media screen and (max-width: 1200px) {
                top: 35%;
                line-height: 1.2;
                font-size: 1.4rem;
            }

            @media screen and (max-width: 1100px) {
                top: 25%;
            }


            @media screen and (max-width: 768px) {
                width: 70%;
                top: 40%;
            }

            @media screen and (max-width: 576px) {
                top: 40%;
            }

            @media screen and (max-width: 490px) {
                top: 35%;
                width: 80%;
            }

            @media screen and (max-width: 420px) {
                top: 30%;
                width: 80%;
            }

            @media screen and (max-width: 378px) {
                top: 25%;
                width: 85%;
            }

            @media screen and (max-width: 378px) and (max-height: 700px) {
                font-size: 1.2rem;
                top: 22%;
            }

            @media screen and (max-width: 340px) and (max-height: 700px) {
                font-size: 1.3rem;
                top: 26%;
            }
            
            @media screen and (max-width: 378px) and (max-height: 520px) {
                font-size: 1.2rem;
                top: 22%;
            }
        }
    }

    .community--box {
        // width: 100%;
        margin-top: 10rem;
        display: flex;
        // justify-content: space-between;
        gap: 2rem;
        flex-wrap: wrap;
        align-items: center;

        &__container {
            display: block;
            width: 15rem;
            position: relative;

            @media screen and (max-width: 845px) {
                width: 20rem;
            }

            @media screen and (max-width: 768px) {
                width: 14rem;
            }

            @media screen and (max-width: 576px) {
                width: 12rem;
            }

            @media screen and (max-width: 430px) {
                width: 14rem
            }

            @media screen and (max-width: 378px) {
                width: 12rem
            }
        }

        &__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @media screen and (max-width: 845px) {
           display: grid;
           grid-template-columns: repeat(3, 30%);
           gap: 3rem;
        }


        @media screen and (max-width: 768px) {
            margin-top: 6rem;
            width: 90%;
        }

        @media screen and (max-width: 576px) {
            width: 95%;
            gap: 2rem;
        }

        @media screen and (max-width: 430px) {
            grid-template-columns: repeat(2, 40%);
        }

        @media screen and (max-width: 300px) {
            grid-template-columns: 50%;
            justify-content: center;
        }

        &-img__container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15rem;
            height: auto;

            @media screen and (max-width: 845px) {
                width: 20rem;
            }

            @media screen and (max-width: 768px) {
                width: 14rem;
            }

            @media screen and (max-width: 576px) {
                width: 12rem;
            }

            @media screen and (max-width: 430px) {
                width: 14rem
            }

            @media screen and (max-width: 378px) {
                width: 12rem
            }

        }
        &-img__container img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }
`;