import styled from "styled-components";
import { fontGrotesk } from "./mixins";


export const TabStyle = styled.div`
    .tab {
        padding: 1rem 2rem;
        cursor: pointer;
        background-color: #EDE4FF;
        ${fontGrotesk({size: "1.125rem", lineHeight: "1.5rem", weight: "700"})};
        text-transform: capitalize;
        color: rgba(148, 113, 222, 0.46);
        box-sizing: border-box;

        &_active {
            background-color: #1D103A;
            ${fontGrotesk({size: "1.125rem", lineHeight: "1.5rem", color: "#EDE4FF", weight: "700"})};

        }
    }
`