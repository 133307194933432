import styled from 'styled-components';

export const MadverseStyle = styled.section`
    width: 100%;
    position: relative;
    
.madverse-bg,
.madverse-tree-container{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
    
.madverse-bg {
    background-color: #153C3B;
    
    @media screen and (max-width: 768px) {
        background-color: transparent;
    }
}
    
.madverse--img {
    position: absolute;
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;

    & img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
        top: 0;
        left: 0;
    }
}
    
.madverse-tree {
    position: absolute;
    width: 35rem;
    height: auto;

    @media screen and (max-width: 768px) {
        display: none;
    }
    
    & img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
}
        
.tree1 {
    width: 50rem;
    top: -15rem;
    left: 0;
}
    
.tree2 {
    width: 50rem;
    z-index: 1;
    top: 0;
    right: 0;
}

.tree3 {
    width: 50rem;
    bottom: -13rem;
    left: 0;
}

.tree4 {
    bottom: 0;
    right: 0;
}
    
.madverse-sun-rays {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: url(${props => props.sunrays});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 1s ease;

    @media screen and (min-width: 1380px) {
        left: -20%;
    }
    
    &.nightMode {
      opacity: 0;
    }
}
    
    
.section__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 90%;

    & .heading {
        width: 70%;

        @media screen and (max-width: 1300px) {
            width: 75%;
        }

        @media screen and (max-width: 1100px) {
            width: 80%;
        }
    }

    @media screen and (max-width: 768px) {
        width: 70%;
    }

    @media screen and (max-width: 576px) {
        width: 95%;
    }

    @media screen and (max-width: 378px) {
        width: 100%;
    }

    &--text {
        position: absolute;
        top: 52%;
        left: 15%;
        text-align: center;
        width: 70%;
        padding: 1rem;
        border-radius: 5px;
        font-size: 1.5rem;
        line-height: 1.5;
        font-weight: 400;
        background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;

        
        @media screen and (max-width: 1200px) {
            top: 50%;
        }

        @media screen and (max-width: 1100px) {
            width: 70%;
            left: 12%;
            font-size: 1.2rem;
        }

        @media screen and (max-width: 1100px) {
            width: 80%;
            left: 8%;
            font-size: 1.2rem;
        }

        @media screen and (max-width: 850px) {
            top: 55%;
            width: 82%;
            left: 7%;
        }

        @media screen and (max-width: 768px) {
            width: 70%;
            left: 15%;
            font-size: 1.2rem;
            top: 45%;
        }

        @media screen and (max-width: 490px) {
            width: 80%;
            left: 10%;
            top: 45%;
            font-size: 1rem;
        }

        @media screen and (max-width: 378px) {
            width: 85%;
            left: 7.5%;
            line-height: 1.2;
            top: 40%;
        }
    }

    &--info {
        width: 60rem;
        height: auto;
        display: block;
        margin-top: 10rem;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}
    
.heading {
    position: relative;
    width: 70rem;
    height: auto;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.heading img,
.section__content--info img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
    
`;