import styled from 'styled-components';

export const HeaderStyle = styled.header.attrs(props => ({className: props.className}))`
    background: rgba(0, 0, 0, .6);
    backdrop-filter: blur(50px)
    saturate(180%);
    box-shadow: 0px 25px 30px rgba(0, 0, 0, .15);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    @media screen and (max-width: 768px) {

        height: auto;

        &.open {
            height: 100vh;
        }
    }


.mdw__main-header--nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 992px) {
        justify-content: center;
    }
    
    @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0B1020;
        flex-direction: column;
        align-items: center;
        padding: 15rem 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-15rem);
        transition: translate 250ms ease-in,
        opacity 250ms ease-in;
    }
}

.logo-link {
    width: 10rem;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        width: 6rem;
    }
}

.logo-link img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.nav-links {
    position: relative;
    padding: .8rem 3rem;
    font-size: 1.2rem;
    font-family: 'Trebuchet MS', 'Fira Sans', sans-serif;
    display: inline-block;
    outline: none;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    color: #A4A4A4;
    line-height: 1.5;
    cursor: pointer;

    @media screen and (max-width: 992px) {
        padding: .8rem 2rem;
    }

    &::after {
        position: absolute;
        width: 100%;
        height: 1rem;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        background-image: url(${(props) => props.bg});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: scaleX(0);
        transition: transform 150ms ease-in-out;
    }

    &:hover,
    &.activeLink {
        color: white;
    }

    &:hover::after,
    &.activeLink::after {
        transform: scaleX(1);
    }

    @media screen and (max-width: 768px) {
        padding: .8rem 1.6rem;
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: #6f6f6f;
        line-height: 1.5;
    }

    @media screen and (min-width: 1400px){
        font-size: 2rem;
    }
}

.lang {
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        cursor: pointer;
    }
}

.accounts {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-family: 'Trebuchet MS', 'Fira Sans', sans-serif;
    margin-right: 1rem;
    color: #E4E4E4;

    @media screen and (max-width: 768px) {
        font-size: 2rem;
        margin-right: 10rem;
    }
}

.mdw-nav__link-account {
    display: block;
    width: 2rem;
    margin-top: 0;
    height: auto;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
   
    & img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    &::after {
        display: none;
    }
    
}

.acct-item span {
    margin-left: 2rem;

    @media screen and (max-width: 768px) {
        display: none;
    }
}
    
.toggle-box {
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 1rem;
        right: 1.2rem;
        z-index: 10000;
        width: 3rem;
        height: auto;
        cursor: pointer;
        transition: width 250ms ease-in;
    }

    & img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 768px) {
    .mdw__main-header--nav.open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    .lang::after {
        display: none;
    }
}

`