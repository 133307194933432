import React, { useEffect } from 'react';
import classNames from 'classnames';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { LogoStyle } from '../styles/logo.style';
import { useLocation } from 'react-router-dom';

const Logo = ({ section }) => {
  const {pathname} = useLocation()

  const logo = 'https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317466/madwallet/logo_fa5qg3.png';

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
  },[]);

    const scrollToTop = () => {
      gsap.to(window, { scrollTo: 0 });
    }

    let logoClass = classNames({
      'header__logo--link': section === 'header',
      'footer__logo--link': section === 'footer'
    })
    
  return (
    <LogoStyle>
      <button className={logoClass} 
        onClick={() => {
          if (pathname === "/") {
            scrollToTop()
          }
        }}
      >
          <img src={logo} alt="our logo"/>
      </button>
    </LogoStyle>
  )
}

export default Logo

// let logoClass = classNames({
    //   'logo-link': section === 'header',
    //   'footer__logo--link': section === 'footer'
    // })
