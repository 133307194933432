import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";

import { screenSizes } from "../helpers/screenSizes";
import { LandingStyle } from "../styles/landing.style";
import { StyledButton } from "../styles/button.style";
import useMediaQuery from "../hooks/useMediaQuery";
import Stars from "./Stars";
import backgroundWebp from "../assets/images/background.webp";

// images
export const comet =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317431/madwallet/comet_wlsvgc.png";
  const planet1 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317432/madwallet/planet1_zeiztu.png";
  const astroid3 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317431/madwallet/astroid3_bkb1lm.png";
  const astroid1 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317431/madwallet/astroid1_lnnrqa.png";
  const astroid2 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317431/madwallet/astroid2_llfy6h.png";
  const spaceship =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317433/madwallet/spaceship_upadlq.png";
  const introFrame =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317435/madwallet/introFrame_q086v0.png";
  const spaceman =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317437/madwallet/spaceman_jqfows.png";
  const planet2 =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317438/madwallet/planet2_hxhqcc.png";
// const background =
//   "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317433/madwallet/background_s8wbyd.jpg";
  export const button =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317446/madwallet/button_mfjtiw.png";
  export const buttonClicked =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317431/madwallet/buttonClicked_zdsllh.png";
  const introFrameMb =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317434/madwallet/introFrameMb_fpdjcw.png";

const Home = () => {
  // images


  const landing = useRef(null);

  const [screens] = useState(screenSizes);
  const [buttonClick, setButtonClick] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Hiding commet
    let hideCommet = gsap.timeline({
      scrollTrigger: {
        trigger: landing.current,
        scroller: "body",
        start: "top top",
        end: `+=30`,
        scrub: 1,
        invalidateOnRefresh: true,
      },
    });
    hideCommet.to(".metero", { opacity: 0 });

    // Default animation for landing page
    gsap
      .timeline()
      .to(".spaceship", {
        x: 80,
        y: -120,
        duration: 8,
        repeat: -1,
        yoyo: true,
        ease: "none",
      })
      .fromTo(
        ".metero",
        { x: 50, y: -50, scale: 1, yoyo: true },
        { x: -1000, y: 300, duration: 4, scale: 0, repeat: -1, repeatDelay: 5 },
        "<"
      )
      .to(
        ".meteros",
        {
          rotation: 360,
          transformOrigin: "center",
          repeat: -1,
          ease: "none",
          duration: 20,
        },
        "<"
      )
      .to(
        ".astraunut",
        {
          translateX: 20,
          translateY: 20,
          duration: 4,
          repeat: -1,
          yoyo: true,
          ease: "linear",
        },
        "<"
      )
      .to(
        ".spaceman-wrapper",
        {
          rotate: -10,
          duration: 4,
          repeat: -1,
          yoyo: true,
          ease: "linear",
        },
        "<"
      )
      .to(
        ".metero1",
        {
          rotation: 360,
          transformOrigin: "center",
          repeat: -1,
          duration: 12,
          ease: "none",
        },
        "<"
      )
      .to(
        ".metero3",
        {
          rotation: 360,
          transformOrigin: "center",
          repeat: -1,
          duration: 8,
          ease: "none",
        },
        "<"
      )
      .to(
        ".world-two",
        {
          rotate: 360,
          yoyo: true,
          transformOrigin: "center center",
          repeat: -1,
          duration: 40,
          ease: "none",
        },
        "<"
      );
  }, []);

  useEffect(() => {
    let landingAnimations = gsap.timeline({
      scrollTrigger: {
        trigger: landing.current,
        scroller: "body",
        start: "top top",
        scrub: 1,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true,
      },
    });

    let playLandingAnimations = (options) => {
      landingAnimations
        .to("#landing", { backgroundSize: "100% 100%" })
        .to(
          ".mdw-world",
          {
            scale: 0.25,
            x: options.worldOne.xPosition,
            y: options.worldOne.yPosition,
          },
          "<"
        )
        .to(".spaceship", { scale: 0.2 }, "<")
        .to(
          ".metero1",
          {
            scale: 0.2,
            x: options.meteroOne.xPosition,
            y: options.meteroOne.yPosition,
          },
          "<"
        )
        .to(
          ".metero2",
          {
            scale: 0.2,
            x: options.meteroTwo.xPosition,
            y: options.meteroTwo.yPosition,
          },
          "<"
        )
        .to(
          ".metero3",
          {
            scale: 0.2,
            x: options.meteroThree.xPosition,
            y: options.meteroThree.yPosition,
          },
          "<"
        )
        .to(".content h1", { opacity: 0, duration: 0.2 }, "<")
        .to(".content p", { opacity: 0, duration: 0.2 }, "<")
        .to(".interactive", { opacity: 0, duration: 0.2 }, "<")
        .to(".space-world", { left: "50%" }, "<")
        .to(".world-two", { scale: 1, left: options.worldTwo.left }, "<")
        .to(
          ".spaceman-wrapper",
          { top: options.spacemanWrap.top, left: options.spacemanWrap.left },
          "<"
        )
        .to(".astraunut", { scale: 0.4 }, "<")
        .fromTo(
          ".intro-message",
          { y: 400, opacity: 0 },
          { y: -40, opacity: 1 },
          ">-0.1"
        )
        .to(
          ".content",
          {
            background:
              "linear-gradient(to top, rgba(0, 0, 0, 1), transparent)",
          },
          "<"
        );
    };

    function getMediaQueriesObj() {
      const mediaQueries = screens.reduce((acc, screen) => {
        return {
          ...acc,
          [screen.size]: function () {
            playLandingAnimations(screen.options);
          },
        };
      }, {});
      return mediaQueries;
    }

    ScrollTrigger.matchMedia(getMediaQueriesObj());
  }, [screens]);

  const matches = useMediaQuery("(max-width: 768px)");

  const navigate = useNavigate();

  return (
    <LandingStyle id="landing" bg={backgroundWebp} ref={landing}>
      <Stars />
      <div className="metero">
        <img src={comet} alt="falling metero" />
      </div>
      <div className="mdw-world">
        <img src={planet1} alt="world from space" />
      </div>
      <div className="meteros metero3">
        <img src={astroid3} alt="Third moving astroid" />
      </div>
      <div className="meteros metero1">
        <img src={astroid1} alt="moving astroid" />
      </div>
      <div className="meteros metero2">
        <img src={astroid2} alt="Second moving astroid" />
      </div>
      <div className="spaceship">
        <img src={spaceship} alt="alien spaceship" />
      </div>

      <div className="content">
        <h1 className="title">
          Welcome to <span>Madwallet</span>
        </h1>
        <p>A Secure Multi-chain Web3 DeFi Wallet and RWA Aggregator.</p>
        <div className="interactive">
          <StyledButton
            bg={buttonClick ? buttonClicked : button}
            onClick={() => {
              setButtonClick(true);
              navigate("/download");
            }}
          >
            <span>Download Extension</span>
          </StyledButton>
          {/* <StyledOpenButton bg={buttonOpen}>
            <div>
              <span>
                <i className="fa fa-play" aria-hidden="true"></i>
              </span>
              <span>video</span>
            </div>
          </StyledOpenButton> */}
        </div>
      </div>
      <div className="intro-message">
        <img
          src={matches ? introFrameMb : introFrame}
          alt="introducing madwallet"
        />
        <p className="intro-text">
          MAD (Metaverse Advanced Development) Wallet grants users secure access
          to multiple metaverse implementations across various blockchains.
          MadWallet's Real-World Asset (RWA) aggregator provides unmatched
          access to high-yield opportunities and facilitates cross-chain token
          bridging.
        </p>
      </div>
      <div className="space-world">
        <div className="spaceman-wrapper">
          <div className="astraunut">
            <img src={spaceman} alt="spaceman in space" />
          </div>
        </div>
        <div className="world-two">
          <img src={planet2} alt="into the world" />
        </div>
      </div>
    </LandingStyle>
  );
};

export default Home;
