import React, { useRef, useEffect} from "react";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import classNames from "classnames";

import { FeatureStyle } from "../styles/features.style";
import useMediaQuery from "../hooks/useMediaQuery";
import {
  savings,
  savingsMsg,
  savingsMsgMb,
  crosschain,
  crosschainMsg,
  crosschainMsgMb,
  marketplace,
  marketplaceMsg,
  marketplaceMsgMb,
  customization,
  customizationMsg,
  customizationMsgMb,
  FeaturesBg,
  featuresMobileNav,
  tabs,
} from "./FeaturesMetadata";
import rwaAggregation from "../assets/images/rwa-aggregator.webp";
import rwaAggregationMb from "../assets/images/rwa-aggregatorMb.webp";

const Features = ({ activeTab }) => {
  // images

  const scrollElement = useRef(null);

  const matches = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

    const sections = gsap.utils.toArray(".panel");

    const getTotalWidth = () =>
      sections.reduce((val, section) => val + section.offsetWidth, 0);
    let nav = gsap.utils.toArray(".features_tab");
    let snapProgress;
    let scrollSpeed = 6;
    let lastScrollTween = Date.now();

    let totalWidth = getTotalWidth();

    let tl = gsap.timeline();

    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 769px)": function () {
        tl.to(sections, {
          x: () => window.innerWidth - totalWidth,
          duration: 1,
          ease: "none",
        });

        ScrollTrigger.create({
          animation: tl,
          trigger: scrollElement.current,
          scroller: "body",
          pinType: "fixed",
          scrub: 1,
          snap: { snapTo: directionalSnap(tl), duration: 0.5 },
          start: "top top",
          end: () => "+=" + totalWidth / scrollSpeed,
          pin: true,
          invalidateOnRefresh: true,
        });
      },

      // mobile
      "(max-width: 768px)": function () {
        tl.to(sections, {
          x: () => window.innerWidth - totalWidth,
          duration: 1,
          ease: "none",
        });

        ScrollTrigger.create({
          animation: tl,
          trigger: scrollElement.current,
          scroller: "body",
          pinType: "fixed",
          anticipatePin: 2,
          scrub: 1,
          start: "top top",
          end: () => "+=" + totalWidth / scrollSpeed,
          pin: true,
          invalidateOnRefresh: true,
        });
      },
    });

    function init() {
      gsap.set(sections, { x: 0 });
      totalWidth = getTotalWidth();
      let position = 0,
        distance = totalWidth - window.innerWidth;
      // add a label for each section to the timeline
      tl.add("label0", 0);
      sections.forEach((section, i) => {
        let progress = position;
        position += section.offsetWidth / distance;
        tl.add("label" + (i + 1), position);
        nav[i].onclick = () => {
          // link clicks should trigger a scroll tween to the appropriate spot
          snapProgress = progress; // save the current progress so that if we can return it in the directionalSnap() when called right after the scrollTo tween is done (because ScrollTrigger would normally look at the velocity and snap, causing it to overshoot to the next section)
          lastScrollTween = Date.now(); // for checking in the directionalSnap() if there was a recent scrollTo that finished, in which case we'd skip the snapping (well, return the current snapProgress)
          gsap.to(window, {
            scrollTo:
              window.innerHeight * 2 + (totalWidth / scrollSpeed) * progress,
            duration: 1,
            overwrite: "auto",
          });
        };
      });
    }

    init();
    ScrollTrigger.addEventListener("refreshInit", init); // on resize, things must be recalculated

    function directionalSnap(timeline) {
      return (value, st) => {
        if (Date.now() - lastScrollTween < 1650) {
          // recently finished doing a tweened scroll (clicked link), so don't do any snapping.
          return snapProgress;
        }
        let a = [],
          labels = timeline.labels,
          duration = timeline.duration(),
          p,
          i;
        for (p in labels) {
          a.push(labels[p] / duration);
        }
        a.sort((a, b) => a - b);
        if (st.direction > 0) {
          for (i = 0; i < a.length; i++) {
            if (a[i] >= value) {
              return a[i];
            }
          }
          return a.pop();
        } else {
          i = a.length;
          while (i--) {
            if (a[i] <= value) {
              return a[i];
            }
          }
        }
        return a[0];
      };
    }
  }, []);

  const horizontalSections = [
    {
      sectionClass: "features__item item1 panel",
      id: "page1",
      messageImage: matches ? savingsMsgMb : savingsMsg,
      image: savings,
      alt: "Defi Savings",
      message:
        "Idle funds on the MADWallet extension will be deposited into DeFi savings and investments powered by Xend Finance's Autoyield DeFi Protocol. This ensures high capital efficiency and utilization.",
    },
    {
      sectionClass: "features__item item2 panel",
      id: "page2",
      messageImage: matches ? crosschainMsgMb : crosschainMsg,
      image: crosschain,
      alt: "cross-chain bridge",
      message:
        "MAD Wallet extension has an in-built multichain bridge feature that allows users to move assets across the chain from a simple interface.",
    },
    {
      sectionClass: "features__item item3 panel",
      id: "page3",
      messageImage: matches ? marketplaceMsgMb : marketplaceMsg,
      image: marketplace,
      alt: "Marketplace",
      message:
        "The NFT Marketplace allows users to mint, buy, sell, and store their NFTs easily. It is equipped with new features such as skins and spine-animated characters that create different experiences for each user.",
    },
    {
      sectionClass: "features__item item4 panel",
      id: "page4",
      messageImage: matches ? customizationMsgMb : customizationMsg,
      image: customization,
      alt: "Customization",
      message:
        "Each MADWallet instance allows customizations such as changing skins and characters. Every MADWallet instance is different from any other MADWallet instance because each skin is an NFT, and each character is also an NFT.",
    },
    {
      sectionClass: "features__item item5 panel",
      id: "page5",
      messageImage: matches ? rwaAggregationMb : rwaAggregation,
      image: crosschain,
      alt: "RWA Aggregator",
      message:
        "MadWallet's Real-World Asset (RWA) aggregator—a powerful tool for high-yield opportunities and seamless cross-chain token swaps. Explore opportunities, navigate transactions, and effortlessly transform your digital asset engagement within MadWallet's secure environment.",
    }
  ];

  const tagName = () => {
    switch (activeTab) {
      case "savings":
        return "DeFi Savings";

      case "cross-chain":
        return "CROSS-CHAiN BRiDGE";

      case "market":
        return "NFT Marketplace";

      case "customization":
        return "customization";
      
      case "rwa-aggregator":
        return "RWA Aggregator";

      default:
        break;
    }
  };

  return (
    <FeatureStyle id="features" bg={FeaturesBg} ref={scrollElement}>
      <>
        {matches ? (
          <div className="features__mobile-tab">
            <img src={featuresMobileNav} alt="mobile navigation" />
            <h3 className="tagname">{tagName()}</h3>
          </div>
        ) : null}
      </>
      <div
        className="features__item--heading">
        {tabs.map((tab, idx) => (
          <div
            key={idx}
            className={classNames("features_tab", {
              activeTab: activeTab === tab.name,
            })}
            id={`item${idx + 1}`}
            name={tab.name}
          >
            <h3>{tab.label}</h3>
          </div>
        ))}
      </div>
      <div className="features__scrollcontainer">
        {horizontalSections.map((section, idx) => (
          <div key={idx} className={section.sectionClass} id={section.id}>
            <div className="features-img">
              <img src={section.image} alt={section.alt} />
            </div>
            <div className="features__message">
              <img src={section.messageImage} alt={section.alt} />
              <p className="features__text">{section.message}</p>
            </div>
          </div>
        ))}
      </div>
    </FeatureStyle>
  );
};

export default Features;
