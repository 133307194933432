import React from 'react'
import { FaqStyle } from '../styles/faqStyle'



const faqData = [
    {
        question: "What are the criteria for winning?",
        answer: `The criteria depends on the airdrop campaign but is based on points accumulated by performing swap and bridge transactions and creating User Generated Contents (UGCs). The points depends on number and volume of swap and bridge transactions performed daily and the amount of UGCs created daily. `
    },
    {
        question: "How is the score updated?",
        answer: `Points (Actually points) are calculated based on the number and volume of swap and bridge transactions performed daily and the amount and type of User Generated Contents (UGCs) created daily.`
    },
    {
        question: "How frequently is the leaderboard updated?",
        answer: `The leaderboard is updated daily.`
    },
]

export const Faqs = (props) => {
    // const [open, setOpen] = React.useState(false)

    return (
        <FaqStyle>
            <p className='faq-header'>Frequently Asked Questions</p>
            {faqData?.map((faq, i) => (
                <Question key={i} question={faq?.question} answer={faq?.answer} />
            ))}
        </FaqStyle>
    )
}

const Question = ({question, answer}) => {
    const [toggle, setToggle] = React.useState(false)

    return (
        <div className="enquiry" >
            <div className="question-box" onClick={() => setToggle(!toggle)}>
                <p id="question">{question}</p>
                <img src={toggle ? "/leaderboard-images/toggle-arrow.svg" : "/leaderboard-images/toggle-arrow.svg"} alt="toggle" id="arrow"/>
            </div>
            {toggle && (
                <p dangerouslySetInnerHTML={{ __html: answer }} id={toggle ? "show-answer" : "hide-answer"}></p>
            )}
        </div>
    )
}
