import cogoToast from "cogo-toast";


export * from "./TableData";


export const truncateAddress = (address) => {
    if (address.length > 15) {
        address = address.slice(0, 4) + '...' + address.slice(-4);
    }
    return address;
}

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
}

export const showToast = (message, type) => {
	if (type) type = type.toLowerCase();

	switch (type) {
		case "success":
			cogoToast.success(message, { position: "top-right" });
			break;
		case "info":
			cogoToast.info(message, { position: "top-right" });
			break;
		case "loading":
			cogoToast.loading(message, { position: "top-right" });
			break;
		case "warn":
			cogoToast.warn(message, { position: "top-right" });
			break;
		case "error":
			cogoToast.error(message, { position: "top-right" });
			break;

		default:
			cogoToast.info(message, { position: "top-right" });
			break;
	}
};


// const terminate = () => {
//     setTimer(false)
//     setSuccess("")
//     setFailure("")
// }