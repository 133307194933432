import styled from 'styled-components';

export const FeatureStyle = styled.section`
    height: 100vh;
    overflow: hidden;
    display: flex;
    background-image: url(${props => props.bg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .features__scrollcontainer {
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .features__item {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20rem 10rem 10rem;

        @media screen and (max-width: 1200px) {
            padding: 15rem 5rem 5rem;
        }

        @media screen and (max-width: 768px) {
            position: relative;
            min-width: 100vw;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        } 

        &--heading {
            position: fixed;
            z-index: 1;
            display: flex;
            justify-content: center;
            left: 0;
            top: 10rem;
            min-width: 100vw;
            overflow-x: hidden;

            @media screen and (max-width: 992px) {
                min-width: 70%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 2rem;
                left: 15%;
            }

            @media screen and (max-width: 768px) {
                min-width: 60%;
                max-height: 0;
                left: 15%;
                top: 18rem;
                overflow: hidden;
            }

            & .features_tab {
                background-color: rgba(0, 0, 0, 0.15);
                padding: 1rem;
                cursor: pointer;
                text-align: center;

                &:hover h3 {
                    background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
                    background-size: 100%;
                    background-repeat: repeat;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent; 
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                }

                &.activeTab {
                    background-image: linear-gradient(to right, #7aedc7, #c5d4ff);

                    & h3{
                        color: #000;
                    }

                    &:hover h3 {
                        background-image: none;
                        color: #000;
                        -webkit-text-fill-color: #000;
                        -moz-text-fill-color: #000;
                     }
                }

                &:not(:first-of-type) {
                    margin-left: 3rem;

                    @media screen and (max-width: 1200px) {
                        margin-left: 1rem;
                    }

                    @media screen and (max-width: 992px) {
                        margin-left: 0;
                    }
                }
            }

            & h3 {
                color: rgba(255, 255, 255, 0.3);
                font-size: 1.2rem;
                font-weight: 700;
                text-transform: uppercase;

                @media screen and (min-width: 1900px) {
                    font-size: 1.5rem;
                }

                @media screen and (max-width: 1300px) {
                    font-size: 1rem;
                }

                @media screen and (max-width: 768px) {
                    font-size: 1.3rem;
                }

                @media screen and (max-width: 378px) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .features-img {
        width: 40rem;
        height: auto;
        
        @media screen and (max-width: 1200px) {
            width: 35rem;
        } 

        @media screen and (max-width: 850px) {
            width: 30rem;
        }

        @media screen and (max-width: 768px) {
            position: absolute;
            width: 30rem;
            top: 78%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        @media screen and (max-width: 600px) {
            width: 25rem;
        }

        @media screen and (max-width: 450px) {
            top: 78%;
            width: 22rem;
        }

        @media screen and (max-width: 385px) {
            top: 78%;
            left: 50%;
            width: 20rem;
        }

        & img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .item2 .features-img {
        width: 35rem;

        @media screen and (max-width: 768px) {
            top: 78%;
            width: 25rem;
            left: 50%;
        }

        @media screen and (max-width: 600px) {
            width: 20rem;
        }
    }

    .item4 .features-img {
        width: 35rem;

        @media screen and (max-width: 768px) {
            top: 78%;
            width: 25rem;
            left: 50%;
        }

        @media screen and (max-width: 600px) {
            width: 20rem;
        }
    }

    .item3 .features-img {
        width: 25rem;

        @media screen and (max-width: 996px) {
            width: 20rem;
        } 

        @media screen and (max-width: 850px) {
            width: 18rem;
            top: 78%;
        }

        @media screen and (max-width: 600px) {
            width: 15rem;
        }

        @media screen and (max-width: 385px) {
            width: 12rem;
        }
    }

    .features__message {
        position: relative;
        width: 55rem;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
            width: 50rem;
        }

        @media screen and (max-width: 996px) {
            width: 53rem;
        } 

        @media screen and (max-width: 850px) {
            width: 50rem;
        }

        & img {
            width: 100%;
            height: auto;
            display: block;
            object-fit: cover;
        }

        @media screen and (max-width: 768px) {
            position: absolute;
            top: 50%;
            width: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @media screen and (max-width: 650px) {
            width: 45%;
            left: 50%;
        }

        @media screen and (max-width: 550px) {
            top: 45%;
            width: 50%;
        }

        @media screen and (max-width: 450px) {
            top: 40%;
            width: 60%;
        }

        @media screen and (max-width: 385px) {
            top: 45%;
            width: 65%;
        }
    }
    .item3 .features__message {
            width: 50rem;

            @media screen and (max-width: 768px) {
                width: 30rem;
            }   

            @media screen and (max-width: 385px) {
                width: 25rem;
            }
        }

    .item4 .features__message {
        /* @media screen and (max-width: 768px) {
            left: 35%;
        }

        @media screen and (max-width: 500px) {
            width: 60%;
        }

        @media screen and (max-width: 415px) {
            width: 65%;
        } */

        @media screen and (max-width: 385px) {
            /* width: 70%; */
            top: 45%;
        }
    }

    .item2 .features__message {
        @media screen and (max-width: 680px) {
            left: 50%;
        }
    }

    .features__text {
        position: absolute;
        top: 50%;
        width: 70%;
        text-align: center;
        font-weight: 400;
        font-size: 1.5rem;
        display: block;
        background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;

        @media screen and (max-width: 1300px) {
            font-weight: 400;
            font-size: 1.5rem;
            display: block;
        }

        @media screen and (max-width: 1200px) {
            width: 80%;
        }


        @media screen and (max-width: 992px) {
            width: 85%;
        }

        @media screen and (max-width: 768px) {
            top: 25%;
            width: 70%;
            line-height: 2;
        }

        @media screen and (max-width: 768px) and (max-height: 1000px) {
            line-height: 1.6;
        }

        @media screen and (max-width: 576px) {
            line-height: 1.5;
            top: 25%;
            width: 80%;
        }

        @media screen and (max-width: 490px) {
            line-height: 1.3;
        }

        @media screen and (max-width: 378px) {
           top: 30%;
           line-height: 1.3;
        }

        @media screen and (max-width: 378px) and (max-height: 700px) {
            top: 30%;
            line-height: 1.4;
            font-size: 1.2rem;
        }

        @media screen and (max-width: 378px) and (max-height: 520px) {
            top: 25%;
            line-height: 1.2;
            font-size: 1.2rem;
        }

        @media screen and (max-width: 340px){
            font-size: 1rem;
        }
    }

.item1 .features__item--heading {
    @media screen and (max-width: 1300px) {
        top: 20%;
        width: 60rem;
        left: 43%;
    }
    
    @media screen and (max-width: 992px) {
        top: 20%;
        width: 45rem;
        left: 40%;
    }

}

.features__mobile-tab {
    position: absolute;
    width: 35%;
    height: auto;
    top: 6rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;

    @media screen and (max-width: 576px) {
        width: 40%;
    }

    @media screen and (max-width: 380px) {
        width: 55%;
        top: 5rem;
    }

    & img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.tagname {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    color: #000;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}

`;