import styled from 'styled-components';
import { backgroundImage, flex, fontGrotesk, screenSize } from './mixins';

export const LeaderboardStyle = styled.div`
    min-height: 0;

    .leaderboard-container {
        font-family: 'Space Grotesk', sans-serif;
        font-weight: normal;
        font-size: 1rem;
        position: relative;
    }

    .ldb-top {
        width: 100%;
        height: 100vh;   //70rem
        background: url("/leaderboard-images/BG Image.png"), #251940;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;

        /* ${screenSize.mac_13`
            height: 75rem;
        `} */
        ${screenSize.mobile`
            height: auto;
            /* 65rem */
        `}

        .back-cont {
            // width: 99%;
            align-self: flex-end;
            cursor: pointer;
            margin: 1rem 1rem 0 0;
            ${flex({direction: "row", align: "center", justify: "flex-end"})};
            gap: 0.2rem;
            span {
                ${fontGrotesk({color: "#FFFFFF", size: "1rem", lineHeight: "1.5rem", weight: "700"})};
            }
        }

        .logo-div {
            width: 100%;
            margin: 3rem 0 2.5rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ldb-main-text {
            font-style: normal;
            font-weight: 700;
            font-size: 2.5rem;
            line-height: 3rem;
            text-align: center;
            color: #F5F1FF;
        }

        .campaign-slides {
            width: 99vw;
            height: 28rem;
            margin: 5rem auto 5rem auto;
            ${flex({direction: "row", justify: "center", align: "center"})};
            gap: 2rem;
            /* border: 2px solid red; */

            ${screenSize.mac_13`
                margin: 2rem auto 5rem auto;
            `}
            ${screenSize.inbetween`
                gap: 4%;
            `}
            //22rem
            ${screenSize.mobile`
                width: 95%;
                height: auto;
                margin: 2rem auto 5rem auto;
                flex-direction: column;
                gap: 2rem;

                img {
                    width: 100%;
                }
            `}
            /* height: 22rem; */

            .ldb-campaign-card {
                width: 40%;    // 50rem
                height: 100%;
                border-radius: 4px;
                /* border: 2px solid yellow; */
                ${screenSize.tab`
                    width: 45%;
                `}
                ${screenSize.inbetween`
                    width: 48%;
                `}
                ${screenSize.mobile`
                    width: 70%;
                `}

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .ldb-curve {
            width: 100%;
            height: 20rem;
            margin-top: auto;
            border-radius: 45%/100px 100px 0 0;
            box-shadow: -23px -52px 28px rgba(13, 2, 38, 0.65);
            background-color: #251940;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;

            ${screenSize.mac_13`
                height: 17rem;
            `}
            ${screenSize.mobile`
                height: 15rem;
                border-radius: 80%/100px 100px 0 0;
            `}

            .MadRanks {
                font-style: normal;
                font-weight: 700;
                font-size: 3.75rem;
                line-height: 4.87rem;
                text-align: center;
                color: #B491FF;
                margin: 0 0 1rem 0;
                ${screenSize.mobile`
                    font-size: 2.5rem;
                    line-height: 3rem;
                   //  margin-bottom: 0rem;
                `}
            }

            p {
                display: flex;
                align-items: center;
                gap: 5rem;
                ${screenSize.mobile`
                gap: 2.5rem;
                `}
            }
            .participants {
                font-style: normal;
                font-weight: 700;
                font-size: 1.68rem;
                line-height: 2.18rem;
                text-align: center;
                color: #E9DFFD;
            }
            .total {
                font-style: normal;
                font-weight: 700;
                font-size: 2rem;
                line-height: 2rem;
                text-align: center;
                color: #C8B8ED;
                background: #1D103A;
                padding: 1rem 2rem;
                border-radius: 5px;
                /* border: 1px solid yellow; */
            }
        }

    }

    /* .center-slide {
        border: 2px solid red;
    } */

    .ldb-down {
        width: 100%;
        height: auto;
        padding: 0 5% 2rem 5%;
        background-color: #251940;
        background-image: url("/leaderboard-images/settings guy 2.png"), linear-gradient(to right, #251940, #251940);
        background-repeat: no-repeat;
        background-position: left 99%;
        background-size: 35% 70%;
        ${screenSize.mobile`
            background-image: none;
            background-color: #251940; 
        `}
       
        .down-top {
            width: 100%;
            height: auto;
            ${flex({direction: "row", align: "unset", justify: "space-between"})};
            ${screenSize.mobile`
                flex-direction: column;
                padding: 2rem 0 0 0;
            `}

            .down-left {
                width: 30%;
                margin-right: 2rem;
                ${screenSize.mobile`
                    width: 100%;
                    margin: 0 0 4rem 0;
                `}
                
                .top-left {
                    width: 100%;
                    ${flex({direction: "column", justify: "center", align: "center"})};
                }

                .top-scorer {
                    ${fontGrotesk({size: "2rem", lineHeight: "2.5rem", weight: "700", color: "#B490FF"})};
                }
                .overall-rank {
                    ${fontGrotesk({size: "1.56rem", lineHeight: "2rem", weight: "700", color: "#B490FF"})};
                }
                .overall-score {
                    ${fontGrotesk({size: "1.5rem", lineHeight: "2rem", weight: "700", color: "#F1EAFF"})};
                }
            }

            .down-right {
                width: 70%;
                ${screenSize.mobile`
                    width: 100%;
                `}

                .ldb-wrapper {
                   // max-height: 50rem;   // 70rem
                    background-color: white;
                    box-shadow: -23px -52px 28px rgba(13, 2, 38, 0.65);
                    border-radius: 10px;
                    ${screenSize.mobile`
                        box-shadow: none;
                    `}

                    .ldb-table-top {
                        width: 100%;
                        padding: 2rem 0;

                        h4 {
                            ${fontGrotesk({size: "32px", lineHeight: "41px", weight: "700", color: "#B490FF"})};
                        }
                        .ldb-table-header {
                            ${flex({justify: "space-between", align: "unset"})};
                            gap: 10%;  
                            padding: 0 2rem;

                            ${screenSize.mobile`
                                flex-direction: column;
                                gap: 2rem;
                            `}

                            .ldb-table-header-left {
                                width: 40%;
                                ${screenSize.mobile`
                                    width: 100%;
                                `}

                                .leaderboard-table-title {
                                    ${fontGrotesk({size: "2rem", lineHeight: "3rem", weight: "700", color: "#251940"})};
                                }

                                .ldb-update-info {
                                    .ldb-update-freq {
                                        ${fontGrotesk({size: "1rem", lineHeight: "1.5rem", weight: "700", color: "#9471DE"})};
                                    }
                                    .ldb-last-update {
                                        span {
                                            ${fontGrotesk({size: "1rem", lineHeight: "1.5rem", weight: "700", color: "#9C9C9C"})};
                                        }
                                        .ldb-update-date {
                                            margin: 0 0.5rem;
                                        }
                                    }
                                }
                            }

                            .search-box {
                                width: 40%;
                                ${screenSize.mobile`
                                    width: 100%;
                                `}
                            }
                        }

                        .ldb_tab-cont {
                            width: 100%;
                            ${flex({direction: "row", align: "center", justify: "unset"})};
                            margin: 2.5rem 0;
                            border-bottom: 2px solid #DCCAFF;
                        }
                        

                        .filter-box {
                            width: 100%;
                            ${flex({direction: "row", align: "center", justify: "center", gap: "1.5rem"})};
                            flex-wrap: wrap;
                            padding: 0 2rem;
                            ${screenSize.mobile`
                                /* margin-top: 2.5rem; */
                            `}
                        }
                    }

                    .ldb-table-cont {
                        margin: 2rem 0 2rem 0;
                        height: 48rem;
                        overflow-y: auto;
                    }
                }

                .tc-cont {
                    margin: 2rem 0 0 0;
                    width: 100%;
                    padding: 0 2rem;

                    .terms {
                        ${fontGrotesk({size: "1rem", lineHeight: "1.25rem", weight: "700", color: "#B9A4E6"})};
                        margin-bottom: 1rem;
                    }
                    .conditions {
                        ${fontGrotesk({size: "0.875rem", lineHeight: "1.125rem", weight: "700", color: "rgba(245, 240, 255, 0.47)"})};
                    }
                }
            } 
        }

        .down-bottom {
            width: 100%;
            margin-top: 6rem;
            ${flex({direction: "column", align: "center"})};
        }

        .ldb-footer {
            width: 100%;
            text-align: center;
            margin: 4rem 0 0 2rem;
            ${fontGrotesk({size: "0.875rem", lineHeight: "1.125rem", weight: "700", color: "#7966A3"})};
        }
    }

`

