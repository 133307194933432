import styled, {css} from "styled-components";
import { backgroundImage, flex, fontGrotesk, screenSize } from "./mixins";

export const CampaignCardStyle = styled.div`
    height: 24rem;
    width: 90%;
    
    background: url("/leaderboard-images/Mask group.svg"), white;
    background-repeat: no-repeat;
    background-position: center;
    // background-size: 100%;
    /* border: 2px solid red; */
    border-radius: 8px;
    padding: 2rem;
    margin: 0 1rem;
    
    box-sizing: border-box;

    ${screenSize.mobile`
        height: 21.5rem;
        padding: 1rem;
        margin: 0 2rem;
    `}

    .card-campaigner {
        ${fontGrotesk({size: "1.125rem", lineHeight: "1.5rem", color: "#251940", weight: 700})};
        text-align: right;
    }

    .card-section {
        margin: 2rem 0 0 0;
        ${screenSize.mobile`
            margin: 1rem 0 0 0;
        `}
        .card-details {
            ${flex({direction: "row", justify: "space-between"})};
            gap: 5%;
            margin-bottom: 2rem;

            .card-campaign-info {
                width: 55%;
                ${fontGrotesk({size: "1.375rem", lineHeight: "1.8rem", color: "#251940", weight: 700})};

                ${screenSize.tab`
                    width: 50%;
                `}
            }
            .card-price-info {
                width: 40%;
                align-self: flex-end;
                ${screenSize.tab`
                    width: 45%;
                `}

                .prize-title {
                    ${fontGrotesk({size: "1.437rem", lineHeight: "1.87rem", color: "#5D5471;", weight: 700})};
                }
                .price-value {
                    ${fontGrotesk({size: "2rem", lineHeight: "2.5rem", color: "#09001B;", weight: 700})};
                    ${screenSize.tab`
                        font-size: 1.5rem;
                        line-height: 2rem;
                    `}
                }
            }
        }
    }
`


// ${props => props.active ? css`
//         min-width: 45%;
//         height: 100%;
//         background-color: white;
//     `
//     : css`
//         min-width: 30%; 
//         height: 80%;
//         background-color: pink;
//     `};
