import React, { useState, useRef, useEffect } from "react";
import {useNavigate} from 'react-router-dom'
import gsap from "gsap";

import { NewsStyle } from "../styles/news.style";
import { StyledButton } from "../styles/button.style";
import useMediaQuery from "../hooks/useMediaQuery";
import opera from "../assets/images/opera.svg";
import brave from "../assets/images/brave.svg";
import chrome from "../assets/images/chrome.svg";
import firefox from "../assets/images/firefox.svg";
import downloadBg from "../assets/images/downloadBg.png";
import downloadBgMb from "../assets/images/downloadBgMb.png";


const DownloadMadwallet = () => {
  const newsletter =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317447/madwallet/newsletter_ixq20v.png";
  const newsBg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317449/madwallet/newsBg_bpsx6h.jpg";
  const button =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317431/madwallet/button_p0mmof.png";
  const buttonClicked =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317447/madwallet/buttonClicked_y1twho.png";


  const [buttonClick, setButtonClick] = useState(false);
  const rocketMan = useRef(null);
  const rocketMan1 = useRef(null);
  const newsRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      let x = e.touches ? e.touches[0].clientX : e.clientX;

      let y = e.touches ? e.touches[0].clientY : e.clientY;

      rocketMan.current = rocketMan1.current;
      
      let left = rocketMan.current.offsetLeft / 8;
      let top = rocketMan.current.offsetTop / 10;

      let offsetLeft = -(x - left) / left;
      let offsetTop = -(y - top) / top;

      gsap.to(rocketMan.current, {
        duration: 1,
        y: `${offsetTop}%`,
        x: `${offsetLeft}%`,
      });
    };

    newsRef.current.addEventListener("mousemove", handleMouseMove);
    newsRef.current.addEventListener("touchstart", handleMouseMove);
    newsRef.current.addEventListener("touchmove", handleMouseMove);
  }, []);

  const matches = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  return (
    <NewsStyle id="download-madwallet" bg={newsBg} ref={newsRef}>
      <div className="newsSlide slide1 active">
        <div className="newsletter__content">
          <div className="newsletter-message">
            <img
              src={matches ? downloadBgMb : downloadBg}
              alt="subscribe to news"
            />
            <p className="newsletter__content--text">Experience the MADverse and the true power of the blockchain.{" "}</p>
          </div>
        </div>
        <div className="newsletter__interactive">
          <StyledButton
            bg={buttonClick ? buttonClicked : button}
            onClick={() => {
              setButtonClick(true)
              navigate('/download')
            }}
          >
            <span>Download extension</span>
          </StyledButton>
          <h4 className="platforms">supported platforms</h4>
          <div className="browsers">
            <span>
              <img src={chrome} alt="chrome" />
              <h4>Chrome</h4>
            </span>
            {/* <span>
              <img src={opera} alt="opera" />
              <h4>Opera</h4>
            </span> */}
            <span>
              <img src={brave} alt="brave" />
              <h4>Brave</h4>
            </span>
            {/* <span>
              <img src={firefox} alt="firefox" />
              <h4>Firefox</h4>
            </span> */}
          </div>
        </div>
        <div className="newsletter-img" ref={rocketMan1}>
          <img src={newsletter} alt="please subscribe" />
        </div>
      </div>
    </NewsStyle>
  );
};

export default DownloadMadwallet;
