import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import opera from "../assets/images/opera.svg";
import brave from "../assets/images/brave.svg";
import chrome from "../assets/images/chrome.svg";
import appImg from "../assets/images/app-img.svg";
import firefox from "../assets/images/firefox.svg";
import logo from "../assets/images/madwallet-logo.svg";
import footerBg from "../assets/images/footer-panel.svg";
import settingsGuy from "../assets/images/settings-guy.svg";
import downloadBg from "../assets/images/downloadPageBg.svg";
import downloadBtn from "../assets/images/download-button.svg";
import { DownloadPageStyles } from "../styles/downloadPage.style";


export default function DownloadExtenstion() {
  const CHROME_URL = 'https://chrome.google.com/webstore/detail/madwallet/gcbjmdjijjpffkpbgdkaojpmaninaion?utm_medium=paid&utm_source=google_ads&utm_content=google_ad&utm_campaign=google_ads_kallo_shopping_assistant&gclid=Cj0KCQiA8aOeBhCWARIsANRFrQED1wzKzwk72BpVWjGscq5EkiI50koMzQJsxYl5dt6h1FGoPORPSwgaApD7EALw_wcB'
  const FIREFOX_URL = 'https://addons.mozilla.org/en-US/firefox/addon/madwallet/'
  
  const [browser, setBrowser] = useState({
    name: '',
    downloadUrl: CHROME_URL
  });

  function getBrowser(userAgent) {
    if (typeof window.navigator.brave !== "undefined") {
      setBrowser({
        ...browser,
        name: 'Brave'
      });
      return;
    }

    if (navigator.userAgent.indexOf("OP") > -1) {
      setBrowser({
        ...browser,
        name: 'Opera'
      });
      return;
    }

    if (userAgent.match(/firefox/i)) {
      setBrowser({
        name: userAgent.match(/firefox/i)[0],
        downloadUrl: FIREFOX_URL});
      return;
    }
    
    if (userAgent.match(/chrome/i)) {
      setBrowser({
        ...browser,
        name: userAgent.match(/chrome/i)[0]});
      return;
    }
  }

  useEffect(() => {
    getBrowser(window.navigator.userAgent);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DownloadPageStyles bg={downloadBg} footerBg={footerBg}>
      <div className="background">
        <nav className="navbar">
          <div className="container">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </nav>

        <div className="content">
          <div className="container">
            <main>
              <div className="left-content">
                <div className="heading">
                  <h1>Install Madwallet for your browser</h1>
                </div>

                <a 
                  className="install-btn"
                  target="_blank" 
                  rel="noreferrer" 
                  href={browser.downloadUrl}
                >
                    <img src={downloadBtn} alt="chrome" />
                    <span>
                      Install Madwallet for {browser.name}
                    </span>
                </a>
              </div>

              <div className="right-content">
                <div className="app-image">
                  <img src={appImg} alt="app screenshot" />
                  <span>
                    <img src={settingsGuy} alt="settings guy" />
                  </span>
                </div>
              </div>
            </main>
          </div>

          <footer>
            <h3>Other Supported Browsers</h3>
            <div className="">
              <div className="browsers">
                <span>
                  <img src={chrome} alt="chrome" />
                  <h4>Chrome</h4>
                </span>
                {/* <span>
                  <img src={opera} alt="opera" />
                  <h4>Opera</h4>
                </span> */}
                <span>
                  <img src={brave} alt="brave" />
                  <h4>Brave</h4>
                </span>
                {/* <span>
                  <img src={firefox} alt="firefox" />
                  <h4>Firefox</h4>
                </span> */}
              </div>
              <p>
                Copyright &copy; {new Date().getFullYear()} Madwallet - All
                Rights Reserved.{" "}
              </p>
            </div>
          </footer>
        </div>
      </div>
    </DownloadPageStyles>
  );
}
