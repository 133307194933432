import {css} from "styled-components";

export const flex = ({direction, justify, align, gap}) => `
    display: flex;
    flex-direction: ${direction};
    align-items: ${align};
    justify-content: ${justify};
    gap: ${gap};
`

export const fontGrotesk = ({size, weight, lineHeight, color}) => `
    font-family: 'Space Grotesk', sans-serif;
    font-size: ${size};
    font-weight: ${weight};
    line-height: ${lineHeight};
    color: ${color};
`

export const backgroundImage = ({imageUrl, position, size}) => `
  background-image: url(${imageUrl});
  background-repeat: no-repeat;
  background-position: ${position};
  background-size: ${size};
`

export const breakpoints = {
    x_small: '414px',
    small: '576px',
    mobile: '768px',
    inbetween: "870px",
    tab: "991px",
    large: "1024px", 
    x_large: '1280px',
    mac_13: "1440px",
   //  desktop: ""
};


export const screenSize = Object.keys(breakpoints).reduce(
    (accumulator, label) => {
      accumulator[label] = (...args) => css`
        @media (max-width: ${breakpoints[label]}) {
          ${css(...args)};
        }
      `;
      return accumulator;
    },
    {}
);
