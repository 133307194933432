import React, { useRef, useEffect} from 'react';
import gsap from 'gsap';
import { LoaderStyle } from '../styles/loader.style';

const Loader = ({isLoading}) => {
  const loaderImg = 'https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317457/madwallet/loaderImg_w5cqhq.png';
  const light = 'https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317457/madwallet/light_swg7vg.png';

  const loader = useRef(null);

  useEffect(() => {
    gsap
    .to(
      loader.current,
      { backgroundImage: "linear-gradient(to left, rgba(229, 2, 5, 0.12), rgba(133, 0, 206, 0.2), rgba(22, 188, 216, 1))", duration: 4, repeat: -1, yoyo: true},
      "<"
    )
  },[])
  return (
    <LoaderStyle bg={loaderImg} bgCover={light} style={{display: isLoading ? 'block': 'none'}}>
      <div className="loader-cover" ref={loader}></div>
    </LoaderStyle>
  )
}

export default Loader
