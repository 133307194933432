import styled from "styled-components";

export const ContactStyle = styled.section`
  position: relative;
  padding: 10rem 5rem;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 378px) and (max-height: 520px) {
    padding: 5rem;
  }

  .footer__content--box {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer__logo {
    margin-bottom: 5rem;

    @media screen and (max-width: 378px) {
      margin-bottom: 5rem;
      margin-top: -5rem;
    }

    &--link {
      display: block;
      width: 25rem;
      height: auto;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;

      @media screen and (max-width: 378px) {
        width: 20rem;
      }

      @media screen and (max-width: 378px) and (max-height: 520px) {
        width: 15rem;
        margin-bottom: -2rem;
        margin-top: 5rem;
      }

      & img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .footer__nav-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 992px) {
      width: 80%;
      display: grid;
      grid-template-columns: repeat(3, 30%);
      gap: 3%;
    }

    @media screen and (max-width: 490px) and (max-height: 700px) {
      gap: 5%;
      width: 90%;
    }

    @media screen and (max-width: 378px) {
      width: 80%;
      display: flex;
      flex-direction: column;
    }
  }

  .footer__nav-item {
    background-image: linear-gradient(
      to right,
      rgba(122, 237, 199, 0.3),
      rgba(197, 212, 255, 0.3)
    );
    padding: 0.5rem 4rem;
    margin-bottom: 4rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (max-width: 378px) {
      margin-bottom: 3rem;
      width: 70%;
    }

    @media screen and (max-width: 378px) and (max-height: 520px) {
      width: 80%;
      margin-bottom: 2rem;
    }

    &.activeLink {
      background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
      &:hover .footer__nav-link {
        background-image: none;
        color: #000;
        -webkit-text-fill-color: #000;
        -moz-text-fill-color: #000;
      }
    }
  }

  .footer__nav-item.activeLink .footer__nav-link {
    color: #000;
  }

  .footer__nav-item:hover .footer__nav-link {
    background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  .footer__nav-link {
    font-size: 1.2rem;
    font-family: "Trebuchet MS", "Fira Sans", sans-serif;
    display: inline-block;
    outline: none;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.5;
    cursor: pointer;
  }

  .footer__nav-item:not(:first-of-type) {
    margin-left: 2rem;

    @media screen and (max-width: 992px) {
      margin-left: 0;
    }
  }

  .footer--copyright {
    margin-top: 25rem;

    @media screen and (max-width: 798px) {
      margin-top: 20rem;
    }

    @media screen and (max-width: 378px) {
      height: 5rem;
      margin-top: -5rem;
    }
  }

  .lang {
    display: none;
  }

  .footer__contact {
    /* background-image: linear-gradient(to right, rgba(122, 237, 199, 0.3), rgba(197, 212, 255, 0.3));
    padding: .5rem 5rem;
    cursor: pointer;
    margin-top: 0;
    font-family: 'Trebuchet MS', 'Fira Sans', sans-serif;

    @media screen and (max-width: 378px) {
        margin-top: 0;
    }

    &:hover h3 {
        background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    &.active{
        background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
        &:hover h3 {
            background-image: none;
            color: #000;
            -webkit-text-fill-color: #000;
            -moz-text-fill-color: #000;
        }
    } */

    padding: 1rem;
    font-size: 1.5rem;
    background-image: linear-gradient(to right, #7aedc7, #c5d4ff);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    & svg {
      filter: drop-shadow(1px 0 5px hsla(170, 0%, 0%, 0.5));
      margin-right: 10px;
      position: relative;
      top: 4px;
    }
  }

  .footer__contact.active h3 {
    color: #000;
  }

  .contact__us {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
  }

  .copyright {
    margin-top: -10rem;
    font-size: 1.3rem;
    font-weight: 400;
    color: #fff;

    @media screen and (max-width: 378px) {
      margin-top: 7rem;
    }
  }
`;
