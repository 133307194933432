import axios from "axios";
import { showToast } from "./index";


const apiResource = () => {
	const baseURL = process.env.REACT_APP_LEADERBOARD_API;

	const service = axios.create({
		baseURL: `${baseURL}`,
		withCredentials: false,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
	});

	// service.interceptors.request.use((config) => {
	// 	const token = _getToken();

	// 	if (!token) return config;
	// 	config.headers["Authorization"] = "Token " + token;
	// 	return config;
	// });

	service.interceptors.response.use(
		(response) => {
			return response?.data;
		},
		(error) => {
			if (error?.response === undefined) {
				showToast("No internet connection", "error");
				return false
			}
			else {
				// const status = error?.response?.status;
				const errors = error?.response?.data;

				// const errorMessage = errors?.error || errors?.message;


				return Promise.reject(errors);
			}
		}
	);

	return {
		get: async (url) => {
			try {
				const data = service.get(url);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		post: async ({ url, payload }) => {
			try {
				const data = service.post(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		patch: async ({ url, payload }) => {
			try {
				const data = service.patch(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		delete: async ({ url, payload }) => {
			try {
				const data = service.delete(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		put: async ({ url, payload }) => {
			try {
				const data = service.put(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},
	};
};

export const apiService = apiResource();
