import React from "react";
import { CommunityStyle } from "../styles/community.style";
import useMediaQuery from "../hooks/useMediaQuery";
import facebookIcon from '../assets/images/facebook.svg';
import mediumIcon from '../assets/images/medium.svg';
import radditIcon from '../assets/images/raddit.svg';
import telegramIcon from '../assets/images/telegram.svg';
import twitterIcon from '../assets/images/twitter.svg';
import instagramIcon from "../assets/images/instagram.png"

const Community = () => {
  const communityFrame =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317371/madwallet/communityFrame_d3veou.png";
  const communityBox =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317370/madwallet/communityBox_zehxgv.png";
  const communityBg =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317371/madwallet/communityBg_ayv9ok.jpg";
  const communityFrameMb =
    "https://res.cloudinary.com/dmiqcs5px/image/upload/v1670317374/madwallet/communityFrameMb_f9jfpq.png";

  const matches = useMediaQuery("(max-width: 768px)");

  return (
    <CommunityStyle id="community" bg={communityBg}>
      <div className="community__content">
        <div className="community__content--container">
          <img
            src={matches ? communityFrameMb : communityFrame}
            alt="community access"
          />
          <p className="community__content--text">
            Here, we love to do things differently because we now live in a different world. Join us if you think you are different.
          </p>
        </div>
        <div className="community--box">
          <a className="community--box__container" href="https://www.facebook.com/profile.php?id=100090323529766"
              target="_blank"
              rel="noopener noreferrer">
            <span
              className="community--box__icon"
            ><img src={facebookIcon} alt="join facebook community" /></span>
            <div className="community--box-img__container">
              <img src={communityBox} alt="community pannel" />
            </div>
          </a>
          {/* <a className="community--box__container" href="http://medium.com/xendfinance/"
              target="_blank"
              rel="noopener noreferrer">
            <span
              className="community--box__icon"
              
            ><img src={mediumIcon} alt="our blog" /></span>
            <div className="community--box-img__container">
              <img src={communityBox} alt="community pannel" />
            </div>
          </a> */}
          {/* <a className="community--box__container" href="https://www.reddit.com/r/XendFinance/"
              target="_blank"
              rel="noopener noreferrer">
            <span
              className="community--box__icon"
            ><img src={radditIcon} alt="ask questions" /></span>
            <div className="community--box-img__container">
              <img src={communityBox} alt="community pannel" />
            </div>
          </a> */}
          {/* <a className="community--box__container" href="http://t.me/xendFinance"
              target="_blank"
              rel="noopener noreferrer">
            <span
              className="community--box__icon"
            ><img src={telegramIcon} alt="join us on telegram" /></span>
            <div className="community--box-img__container">
              <img src={communityBox} alt="community pannel" />
            </div>
          </a> */}
          <a className="community--box__container" href="https://www.instagram.com/madversewallet"
              target="_blank"
              rel="noopener noreferrer">
            <span
              className="community--box__icon"
            ><img src={instagramIcon} alt="follow us on instgarm" /></span>
            <div className="community--box-img__container">
              <img src={communityBox} alt="community pannel" />
            </div>
          </a>
          <a className="community--box__container"  href="https://twitter.com/madwalletverse"
              target="_blank"
              rel="noopener noreferrer">
            <span
              className="community--box__icon"
            ><img src={twitterIcon} alt="follow us on twitter" /></span>
            <div className="community--box-img__container">
              <img src={communityBox} alt="community pannel" />
            </div>
          </a>
        </div>
      </div>
    </CommunityStyle>
  );
};

export default Community;
