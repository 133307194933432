import styled from 'styled-components';

export const DownloadPageStyles = styled.div`
  position: relative;
  background-image: url(${props => props.bg});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;

  .navbar{
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;

    & > .container{
      margin-inline: auto;
      width: 100%;
      padding: 1rem 5rem;

      @media screen and (min-width: 1280px) {
        max-width: 120rem;
      }

      @media screen and (min-width: 1200px) {
        max-width: 100rem;
      }
    }
    
    & img{
      height: 4rem;
    }
  }

  .content{
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    & > .container{
      max-width: 120rem;
      margin-inline: auto;
      width: 100%;
      padding: 1rem 5rem;

      @media screen and (min-width: 1280px) {
        max-width: 120rem;
      }

      @media screen and (min-width: 1200px) {
        max-width: 100rem;
      }
    }

    & main{
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      
      @media screen and (max-width: 568px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 4rem;
      }
    }

    & .left-content{
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: 568px) {
        transform: translateY(30%);
      }
    }

    & .heading{
      width: 65%;
      margin-inline: auto;

      @media screen and (max-width: 568px) {
        width: 100%;
      }

      & > h1{
        text-transform: uppercase;
        color: white;
        text-align: center;
        margin-block: 1rem;
        line-height: 3rem;
      }
    }
    
    & .install-btn:link,
    & .install-btn:visited{
      color: inherit;
    }
  
    & .install-btn{
      margin-top: 4rem;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 568px) {
        margin-bottom: 4rem;
        font-size: 1.3rem;
        line-height: 2rem; 
      }

      & img{
        width: 60%;
        
        @media screen and (max-width: 568px) {
          width: 100%;
          text-align: center;
        }
      }

      & span{
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 568px) {
          // width: 100%;
          text-align: center;
        }
      }
    }
    
    & .right-content{
      height: 100%;

      & .app-image{
        position: relative;

        @media screen and (max-width: 568px) {
          transform: translateY( 45px);
        }

        & img{
          @media screen and (max-width: 568px) {
            transform: translate(13px, 38px);
          }
        }
      
        & span img{
          position: absolute;
          top: 40%
          left: 50%;
          transform: translate(-55%, 90%);

          @media screen and (max-width: 568px) {
            display: none;
          }
        }
      }
    }

    & footer{
      background-image: url(${props => props.footerBg});
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;

      & > .container{
        max-width: 120rem;
        margin-inline: auto;
        width: 100%;
        padding: 1rem 5rem;

        @media screen and (min-width: 1280px) {
          max-width: 120rem;
        }
  
        @media screen and (min-width: 1200px) {
          max-width: 100rem;
        }
      }

      & h3{
        text-transform: uppercase;
        color: white;
        text-align: center;
        padding-top: 3rem;
        margin-bottom: 5rem;
      }

      & h4{
        text-transform: uppercase;
        color: white;
        text-align: center;
        margin-top: 1rem;
      }
      & p{
        text-transform: capitalize;
        color: white;
        opacity: .5;
        text-align: center;
        margin-top: 1rem;
        padding-bottom: 1rem;
      }

      & .browsers{
        display: flex;
        width: 41%;
        margin-inline: auto; 
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 5rem;

        & img{
          z-index: 2;
        }

        & span {
          cursor: pointer;
        }

        @media screen and (max-width: 568px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 6rem;
          text-align: center;
        }
      }
    }
  }
`;