import styled from 'styled-components';

export const LoaderStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-image: url(${props => props.bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .loader-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(${props => props.bgCover});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
`;